import { FormControl, Typography } from '@mui/joy'
import SocialAccountSelectV2 from 'COMPONENTS/forms/SocialAccountSelectV2/SocialAccountSelectV2'
import SocialAccountAvatar from 'COMPONENTS/views/SocialAccountAvatar/SocialAccountAvatar'
import { type NotEmptySocialAccount } from 'HOOKS/useSocialAccounts'
import { type FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { type ControlProps } from '../types'

export interface SocialAccountControlProps extends ControlProps {
  socialAccounts: NotEmptySocialAccount[]
  socialNameFieldName: string
}

export const SocialAccountControl: FC<SocialAccountControlProps> = ({
  name,
  socialNameFieldName,
  socialAccounts,
}) => {
  const { control, setValue } = useFormContext()

  return (
    <FormControl>
      <Typography component="label">Page</Typography>

      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const currentSocialAccount = socialAccounts.find((account) => account.id === field.value)

          return (
            <SocialAccountSelectV2
              sx={{ flex: 1 }}
              placeholder="Choose page"
              value={field.value}
              startDecorator={
                currentSocialAccount ? (
                  <SocialAccountAvatar
                    size="sm"
                    socialAccount={currentSocialAccount}
                    badgeSize={12}
                  />
                ) : null
              }
              onChange={(_, newValue) => {
                field.onChange(newValue)
                const newSocialAccount = socialAccounts.find((account) => account.id === newValue)
                setValue(socialNameFieldName, newSocialAccount?.platform)
              }}
            />
          )
        }}
      />
    </FormControl>
  )
}
