import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import useConfirm from 'HOOKS/useConfirm/useConfirm'
import type { ReactElement } from 'react'
import { useEffect, useState } from 'react'
import {
  useCreatePostTemplateMutation,
  useDeletePostTemplateMutation,
  useEditPostTemplateMutation,
  useGetPostTemplatesQuery,
} from 'STORE/endpoints/postsTemplates'
import type { CloseVerdict } from 'UI/PopupWithInput/PopupWithInput'
import PopupWithInput from 'UI/PopupWithInput/PopupWithInput'

import SplitButton from './components/SplitButton/SplitButton'
import PostTemplateSelect from './PostTemplateSelect'

interface TemplateToolsProps {
  text: string
  onTextChange: (newText: string) => void
}

const UPDATE_BUTTON_TEXT = 'Update current'
const SAVE_AS_NEW_TEXT = 'Save as new'

const TemplateTools = ({ text, onTextChange }: TemplateToolsProps): ReactElement => {
  const confirm = useConfirm()
  const { data: templates = [], refetch: refetchTemplates } = useGetPostTemplatesQuery()
  const [createTemplate] = useCreatePostTemplateMutation()
  const [editTemplate] = useEditPostTemplateMutation()
  const [deleteTemplate] = useDeletePostTemplateMutation()

  const [mode, setMode] = useState<'create' | 'edit' | 'close'>('close')
  const [activeTemplateId, setActiveTemplateId] = useState<string>('')

  useEffect(() => {
    const activeTemplate = templates.toReversed().find(({ template }) => text === template)

    if (activeTemplate?.id) {
      setActiveTemplateId(activeTemplate.id)
    }
  }, [text])

  const handleSelectChange = (_: any, value: string | null): void => {
    if (value === null) {
      return
    }

    const newTemplate = templates.find(({ id }) => id === value)

    if (newTemplate?.template !== undefined) {
      onTextChange(newTemplate?.template)
    } else {
      onTextChange('')
    }
  }

  const handleClose = async (verdict: CloseVerdict): Promise<void> => {
    setMode('close')
    let result

    if (verdict.confirmed) {
      switch (mode) {
        case 'create': {
          result = await createTemplate({ name: verdict.inputValue, template: text }).unwrap()
          break
        }
        case 'edit': {
          if (activeTemplateId === '') {
            throw Error('Editing template does not contain id')
          }
          result = await editTemplate({
            id: activeTemplateId,
            name: verdict.inputValue,
            template: text,
          }).unwrap()
          break
        }
      }
      await refetchTemplates()

      if (result?.id) {
        setActiveTemplateId(result.id)
      }
    }
  }

  const handleSaveAsNewClick = (): void => {
    setMode('create')
  }

  const handleEditClick = (): void => {
    setMode('edit')
  }

  const handleDeleteClick = async (idToRemove: string): Promise<void> => {
    if (idToRemove === '') {
      throw Error('Deleting template does not contain id')
    }

    const isConfirmed = await confirm({
      question: 'Are you sure you want to delete this template?',
    })

    if (!isConfirmed) {
      return
    }

    await deleteTemplate(idToRemove)

    void refetchTemplates()
    if (templates.length > 0) {
      // setActiveTemplateId(templates[0].id)

      if (templates[0].template !== undefined) {
        onTextChange(templates[0].template)
      }
    }
  }

  const handleOptionClick = (option: string): void => {
    if (option === SAVE_AS_NEW_TEXT) {
      handleSaveAsNewClick()
      return
    }

    handleEditClick()
  }

  const activeTemplate = templates.find((template) => template.id === activeTemplateId)
  // const isCanBeDeleted = activeTemplateId !== '' && activeTemplate?.type !== 'SYSTEM'
  const isCanBeSavedAsNew = text !== activeTemplate?.template
  const isCanBeUpdated = activeTemplate?.type === 'USER' && isCanBeSavedAsNew
  const hasSplitButton = isCanBeSavedAsNew && isCanBeUpdated
  const isOpen = mode === 'edit' || mode === 'create'

  return (
    <Box display="flex" gap={2}>
      {isOpen && (
        <PopupWithInput
          initialValue={activeTemplate?.name ?? ''}
          onClose={handleClose}
          open={isOpen}
        />
      )}
      <PostTemplateSelect
        placeholder="Choose template"
        sx={{ flex: 1, height: 40 }}
        value={activeTemplate?.id}
        onChange={handleSelectChange}
        onDeleteClick={handleDeleteClick}
      />
      {!hasSplitButton && isCanBeUpdated && (
        <Button variant="outlined" onClick={handleEditClick}>
          {UPDATE_BUTTON_TEXT}
        </Button>
      )}
      {!hasSplitButton && !isCanBeUpdated && (
        <Button variant="outlined" onClick={handleSaveAsNewClick} disabled={!isCanBeSavedAsNew}>
          {SAVE_AS_NEW_TEXT}
        </Button>
      )}
      {hasSplitButton && (
        <SplitButton
          options={[SAVE_AS_NEW_TEXT, UPDATE_BUTTON_TEXT]}
          handleOptionClick={handleOptionClick}
        />
      )}
      {/* {isCanBeDeleted && <RemoveButton disabled={!isCanBeDeleted} onClick={handleDeleteClick} />} */}
    </Box>
  )
}

export default TemplateTools
