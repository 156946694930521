import { type PostFormValues } from 'PAGES/Post/components/PostsFormContainer/components/PostsForm/components/PostForm/PostForm.types'
import * as yup from 'yup'
import { type ObjectSchema } from 'yup'

import { type MediaValue } from '@/types'

const checkMediaForVideo = (mediaArray: MediaValue[]): boolean => {
  return mediaArray.some(
    (mediaItem: MediaValue) => mediaItem?.url && mediaItem.url.endsWith('.mp4'),
  )
}

const postFormSchema: ObjectSchema<PostFormValues> = yup
  .object({
    socialName: yup.string().oneOf(['FACEBOOK', 'INSTAGRAM']).required(),
    socialAccountId: yup.string().required('Required'),
    postType: yup.string().oneOf(['POST', 'STORY', 'REEL']).required('Required'),
    productId: yup.string().required('Required'),
    postId: yup.string().optional(),
    sourceAccountId: yup.string().optional(),
    platform: yup.string().oneOf(['SHOPIFY']).optional(),
    media: yup
      .array()
      .of(
        yup.object({
          frontId: yup.string().required(),
          id: yup.string().required(),
          url: yup.string(),
        }),
      )
      .min(1, 'Field must have at least 1 item')
      .required(),
    caption: yup.string().max(2200, 'Field must be at most 2200 characters'),
  })
  .test('check-video', 'POST cannot contain videos', function (post) {
    const { postType, media } = post
    if (postType === 'POST' && checkMediaForVideo(media)) {
      return this.createError({ message: 'Videos are not allowed in POST type.' })
    }
    return true
  })

export { postFormSchema }
