import IconButton from '@mui/joy/IconButton'
import Tooltip from '@mui/joy/Tooltip'
import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { type CampaignResponse } from '@/types'

import PostIndicatorIcon from '../PostIndicatorIcon/PostIndicatorIcon'
import { getTooltipTitle } from './PostIndicator.utils'

interface PostIndicatorProps {
  campaign?: CampaignResponse | null
  hasScheduledTime?: boolean
}

export const PostIndicator: FC<PostIndicatorProps> = ({ campaign, hasScheduledTime }) => {
  const hasCampaign = !!campaign
  const navigate = useNavigate()

  const handleClick = (): void => {
    if (hasCampaign) {
      navigate(`/campaigns/${campaign?.id ?? ''}`)
    }
  }

  const icon = (
    <PostIndicatorIcon
      hasCampaign={hasCampaign}
      hasScheduledTime={hasScheduledTime}
      color="var(--joy-palette-neutral-600)"
    />
  )

  return (
    <Tooltip
      variant="solid"
      title={getTooltipTitle({ hasCampaign, hasScheduledTime, campaignName: campaign?.name })}
      arrow
    >
      {hasCampaign ? (
        <IconButton
          onClick={handleClick}
          size="sm"
          variant="plain"
          sx={{ minHeight: 'auto', minWidth: 'auto' }}
        >
          {icon}
        </IconButton>
      ) : (
        <div>{icon}</div>
      )}
    </Tooltip>
  )
}
