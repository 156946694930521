import { Box, ListItemDecorator } from '@mui/joy'
import Option from '@mui/joy/Option'
import Select, { type SelectProps } from '@mui/joy/Select'
import { useGetPostTemplatesQuery } from 'STORE/endpoints/postsTemplates'
import { Spinner } from 'UI/index'

import RemoveButton from './components/RemoveButton/RemoveButton'

type PostTemplateSelectProps<Multiple extends boolean> = SelectProps<string, Multiple> & {
  onDeleteClick: (id: string) => void
}

const PostTemplateSelect = <Multiple extends boolean = false>(
  props: PostTemplateSelectProps<Multiple>,
): React.ReactNode => {
  const { onDeleteClick } = props
  const { data: postTemplates = [], isLoading: isPostTemplatesLoading } = useGetPostTemplatesQuery()

  const createDeleteClickHandler = (id: string) => (): void => {
    onDeleteClick(id)
  }

  return (
    <Select {...props}>
      {isPostTemplatesLoading && (
        <Option value="loading" disabled>
          <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
            <Spinner size="sm" />
          </Box>
        </Option>
      )}

      {postTemplates.length === 0 && (
        <Option value="">
          <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
            No templates
          </Box>
        </Option>
      )}

      {!isPostTemplatesLoading &&
        postTemplates?.map(({ id, name, type }) => {
          if (!id) return null

          const isCanBeDeleted = type !== 'SYSTEM'

          return (
            <Option sx={{ display: 'flex', justifyContent: 'space-between' }} key={id} value={id}>
              <span>{name}</span>
              {isCanBeDeleted && (
                <ListItemDecorator>
                  <RemoveButton onClick={createDeleteClickHandler(id)}></RemoveButton>
                </ListItemDecorator>
              )}
            </Option>
          )
        })}
    </Select>
  )
}

export default PostTemplateSelect
