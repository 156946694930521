import { FormControl, Typography } from '@mui/joy'
import ErrorMessage from 'COMPONENTS/forms/ErrorMessage/ErrorMessage'
import TimeSelect from 'COMPONENTS/forms/TimeSelect/TimeSelect'
import { type FC } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'

import { LABEL_SX } from '../constants'
import { type ControlProps } from '../types'

export const TimeControl: FC<ControlProps> = ({ name }) => {
  const { control } = useFormContext()
  const {
    fieldState: { error: timeError },
  } = useController({ control, name })

  return (
    <FormControl>
      <Typography sx={LABEL_SX}>Time</Typography>

      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <TimeSelect
              color={timeError !== undefined ? 'danger' : 'neutral'}
              placeholder="Choose time"
              value={field.value ?? []}
              onChange={(e, newValue) => {
                field.onChange(newValue)
              }}
            />
          )
        }}
      />

      <ErrorMessage error={timeError} />
    </FormControl>
  )
}
