import { FormControl, Typography } from '@mui/joy'
import DaysSelect from 'COMPONENTS/forms/DaysSelect/DaysSelect'
import ErrorMessage from 'COMPONENTS/forms/ErrorMessage/ErrorMessage'
import { type FC } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'

import { LABEL_SX } from '../constants'
import { type ControlProps } from '../types'

export const DaysControl: FC<ControlProps> = ({ name }) => {
  const { control } = useFormContext()

  const {
    fieldState: { error: daysError },
  } = useController({ control, name })

  return (
    <FormControl>
      <Typography sx={LABEL_SX}>Frequency</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <DaysSelect
              color={daysError !== undefined ? 'danger' : 'neutral'}
              value={field.value ?? []}
              onChange={(e, newValue) => {
                field.onChange(newValue)
              }}
              placeholder="Choose frequency"
              multiple
            />
          )
        }}
      />
      <ErrorMessage error={daysError} />
    </FormControl>
  )
}
