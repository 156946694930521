import FormControl from '@mui/joy/FormControl'
import RadioGroup from '@mui/joy/RadioGroup'
import Typography from '@mui/joy/Typography'
import type { ChangeEvent, FC } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import { TbFreezeRow, TbHistoryToggle, TbSquarePlus } from 'react-icons/tb'
import Radio from 'UI/Radio/Radio'

import { PostFormField } from '../../PostForm.types'
import { labelsSx } from '../../styles'

export const PostTypeControl: FC = () => {
  const { control } = useFormContext()

  const {
    field: { value: socialName },
  } = useController({ control, name: PostFormField.SocialName })

  return (
    <FormControl>
      <Typography sx={labelsSx} id="post-title-label" component="label">
        Post type
      </Typography>

      <Controller
        name={PostFormField.PostType}
        control={control}
        render={({ field }) => (
          <RadioGroup
            value={field.value ?? ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              field.onChange(event.target.value)
            }}
            variant="outlined"
            orientation="horizontal"
          >
            <Radio
              overlay
              disableIcon
              value="POST"
              label={
                <>
                  <TbSquarePlus />
                  <Typography>Post</Typography>
                </>
              }
            />
            {socialName === 'INSTAGRAM' && (
              <Radio
                overlay
                value="STORY"
                disableIcon
                label={
                  <>
                    <TbHistoryToggle />
                    <Typography>Story</Typography>
                  </>
                }
              />
            )}
            <Radio
              overlay
              value="REEL"
              disableIcon
              label={
                <>
                  <TbFreezeRow />
                  <Typography>Reel</Typography>
                </>
              }
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}
