import Box from '@mui/joy/Box'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import FormCard from 'COMPONENTS/views/FormCard/FormCard'
import useSocialAccounts from 'HOOKS/useSocialAccounts'
import { SpecialInstructionControl } from 'PAGES/Campaign/components/CampaignForm/controls/SpecialInsturctionControl/SpecialInstructionControl'
import { type FC } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { CaptionType } from 'TYPES/index'

import { CampaignFormFields } from './CampaignForm.types'
import { CaptionControl } from './controls/CaptionControl/CaptionControl'
import { CaptionTypeControl } from './controls/CaptionTypeControl/CaptionTypeControl'
import { CollectionsControl } from './controls/CollectionsControl/CollectionsControl'
import { DaysControl } from './controls/DaysControl/DaysControl'
import { MediaRuleControl } from './controls/MediaRuleControl/MediaRuleControl'
import { NameControl } from './controls/NameControl/NameControl'
import { PostTypeControl } from './controls/PostTypeControl/PostTypeControl'
import { ProductRuleTypeControl } from './controls/ProductRuleTypeControl/ProductRuleTypeControl'
import { ShopControl } from './controls/ShopControl/ShopControl'
import { SocialAccountControl } from './controls/SocialAccountControl/SocialAccountControl'
import { TagsControl } from './controls/TagsControl/TagsControl'
import { TimeControl } from './controls/TimeControl/TimeControl'
import { TypeControl } from './controls/TypeControl/TypeControl'
import SelectionTypeAlert from './SelectionTypeAlert'

const CampaignForm: FC = () => {
  const { control } = useFormContext()
  const { socialAccounts = [] } = useSocialAccounts()

  const {
    field: { value: sourceAccountId },
  } = useController({ control, name: CampaignFormFields.SourceAccountId })

  const {
    field: { value: selectionType },
  } = useController({ control, name: CampaignFormFields.Type })

  const {
    field: { value: postType },
  } = useController({ control, name: CampaignFormFields.PostType })

  const {
    field: { value: captionType },
  } = useController({ control, name: CampaignFormFields.CaptionType })

  const { field: productRuleType } = useController({
    control,
    name: CampaignFormFields.ProductRuleType,
  })

  return (
    <Stack gap={1}>
      <FormCard>
        <Stack gap={4}>
          <Typography level="h3">Campaign details</Typography>

          <SocialAccountControl
            name={CampaignFormFields.SocialAccountId}
            socialNameFieldName={CampaignFormFields.SocialName}
            socialAccounts={socialAccounts}
          />

          <NameControl name={CampaignFormFields.Name} />

          <ShopControl name={CampaignFormFields.SourceAccountId} />

          <TypeControl name={CampaignFormFields.Type} />

          <SelectionTypeAlert selectionType={selectionType} />

          <ProductRuleTypeControl name={CampaignFormFields.ProductRuleType} />

          {productRuleType.value === 'COLLECTIONS' && (
            <CollectionsControl
              name={CampaignFormFields.CollectionIds}
              sourceAccountId={sourceAccountId}
            />
          )}

          {productRuleType.value === 'TAGS' && (
            <TagsControl name={CampaignFormFields.Tags} sourceAccountId={sourceAccountId} />
          )}
        </Stack>
      </FormCard>

      <FormCard>
        <Stack gap={4}>
          <Typography level="h3">Template</Typography>

          <PostTypeControl name={CampaignFormFields.PostType} />

          <MediaRuleControl name={CampaignFormFields.MediaRule} />

          {postType !== 'STORY' && (
            <>
              <CaptionTypeControl name={CampaignFormFields.CaptionType} />

              {captionType === CaptionType.Manual && (
                <CaptionControl
                  name={CampaignFormFields.PostTemplates}
                  sourceAccountId={sourceAccountId}
                />
              )}
              {captionType === CaptionType.Ai && (
                <SpecialInstructionControl name={CampaignFormFields.SpecialInstruction} />
              )}
            </>
          )}
        </Stack>
      </FormCard>

      <FormCard>
        <Stack gap={4}>
          <Typography level="h3">Schedule</Typography>

          <Stack direction="row" gap={2} flex={1}>
            <Box sx={{ flex: 1 }}>
              <DaysControl name={CampaignFormFields.Days} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TimeControl name={CampaignFormFields.Time} />
            </Box>
          </Stack>
        </Stack>
      </FormCard>
    </Stack>
  )
}

export default CampaignForm
