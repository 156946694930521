import Checkbox from '@mui/joy/Checkbox'
import { type ChangeEvent, type FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { DatePipe } from '../../../../components/views'
import { type CampaignResponse } from '../../../../types'
import SortLabel from '../../../../ui/SortLabel/SortLabel'
import Table from '../../../../ui/Table/Table'
import { isNotNullable } from '../../../../utils/typesHelper'
import PostAccountContainer from '../../../Posts/components/PostAccountContainer/PostAccountContainer'
import { type CampaignTableProps } from '../../types'
import EmptyCampaigns from '../EmptyCampaigns/EmptyCampaigns'
import ActiveCampaignSkeletonRow from './ActiveCampaignSkeletonRow'
const CAMPAINGS_SKELETON: CampaignResponse[] = [{ id: 'skeleton1' }]

const ActiveCampaignTable: FC<CampaignTableProps> = ({
  campaigns = [],
  isLoading = false,
  selected = [],
  onSelectedChange,
  ...props
}) => {
  const navigate = useNavigate()

  const onSelectAllCampaigns = (event: ChangeEvent<HTMLInputElement>): void => {
    onSelectedChange?.(event.target.checked ? campaigns.map((campaign) => campaign.id ?? '') : [])
  }

  const handleSelectCampaign =
    (campaign: CampaignResponse) => (event: ChangeEvent<HTMLInputElement>) => {
      onSelectedChange?.((ids) =>
        event.target.checked
          ? ids.concat(campaign?.id as string)
          : ids.filter((itemId) => itemId !== (campaign?.id ?? '')),
      )
    }
  return (
    <Table
      items={isLoading ? CAMPAINGS_SKELETON : campaigns}
      isLoading={isLoading}
      renderHeader={() => (
        <tr>
          <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
            <Checkbox
              variant="outlined"
              size="md"
              color="neutral"
              sx={{ verticalAlign: 'text-bottom' }}
              indeterminate={selected.length > 0 && selected?.length !== campaigns.length}
              checked={selected.length > 0 && selected?.length === campaigns.length}
              onChange={onSelectAllCampaigns}
            />
          </th>
          <th style={{ width: 730, padding: '12px 6px' }}>
            <SortLabel label="Title" />
          </th>
          <th style={{ width: 220, padding: '12px 6px' }}>
            <SortLabel label="Created on" />
          </th>
          <th style={{ width: 220, padding: '12px 6px' }}>
            <SortLabel label="Page" />
          </th>
        </tr>
      )}
      renderRow={(campaign) => (
        <tr key={campaign.id}>
          <td style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
            <Checkbox
              variant="outlined"
              size="md"
              color="neutral"
              sx={{ verticalAlign: 'text-bottom' }}
              checked={selected?.includes(campaign?.id ?? '')}
              onChange={handleSelectCampaign(campaign)}
            />
          </td>
          <td
            onClick={() => {
              if (campaign.id !== undefined) {
                navigate(campaign.id)
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            {campaign.name}
          </td>
          <td>
            {isNotNullable((campaign as any)?.createdAt) && (
              <DatePipe date={(campaign as any)?.createdAt} />
            )}
          </td>
          <td>
            <PostAccountContainer
              postType={campaign?.postType}
              socialAccountId={campaign?.socialAccount?.id}
            />
          </td>
        </tr>
      )}
      renderSkeletonRow={(campaign) => <ActiveCampaignSkeletonRow key={campaign.id} />}
      renderEmptyTable={() => <EmptyCampaigns />}
      {...props}
    />
  )
}

export default ActiveCampaignTable
