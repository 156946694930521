import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { FormGroup } from '@mui/material'
import { TemplateTools } from 'COMPONENTS/ai'
import ErrorMessage from 'COMPONENTS/forms/ErrorMessage/ErrorMessage'
import type { FC } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import { isNil } from 'UTILS/typesHelper'

import { PostFormField } from '../../PostForm.types'
import { labelsSx } from '../../styles'
import { CaptionTools } from './components/CaptionTools/CaptionTools'

export const CaptionControl: FC = () => {
  const { control } = useFormContext()

  const {
    fieldState: { error: captionError },
    field: { value: captionValue },
  } = useController({ control, name: PostFormField.Caption })

  const {
    field: { value: sourceAccountId },
  } = useController({ control, name: PostFormField.SourceAccountId })

  const {
    field: { value: productId },
  } = useController({ control, name: PostFormField.ProductId })

  return (
    <FormGroup>
      <Typography id="post-caption-label" sx={labelsSx} component="label">
        Caption
      </Typography>
      <Controller
        name={PostFormField.Caption}
        control={control}
        render={({ field }) => {
          return (
            <Stack gap={1}>
              <TemplateTools
                text={field.value ?? ''}
                onTextChange={(newValue) => {
                  field.onChange(newValue)
                }}
              />
              <CaptionTools
                value={field.value ?? ''}
                onChange={field.onChange}
                productId={productId}
                sourceAccountId={sourceAccountId}
              />
            </Stack>
          )
        }}
      />

      <Typography level="body-sm" sx={{ mt: '6px' }}>
        {isNil(captionValue)
          ? '2,200 characters'
          : `${String(captionValue).trim()?.length} of 2200 characters`}
      </Typography>

      <ErrorMessage error={captionError} />
    </FormGroup>
  )
}
