import { type SocialType } from '../types'

export const isNotNullable = <Type>(value: Type): value is NonNullable<Type> =>
  value !== undefined && value !== null

export const isNotBlank = <Type>(value: Type): value is NonNullable<Type> =>
  value !== undefined && value !== null && value !== ''

export const isNil = (value: unknown): value is null | undefined =>
  value === null || value === undefined

export const isValidSocialType = (value?: string): value is SocialType =>
  value === 'FACEBOOK' || value === 'INSTAGRAM' || value === 'PINTEREST'
