import Typography, { type TypographyProps } from '@mui/joy/Typography'
import { PostIndicator } from 'PAGES/Posts/components/PostIndicator/PostIndicator'
import { type FC } from 'react'
import { isNotBlank } from 'UTILS/typesHelper'

import { type PostResponse } from '@/types'

interface PostTitleProps extends TypographyProps {
  post: PostResponse
}

const PostTitle: FC<PostTitleProps> = ({ post, sx, ...props }) => {
  const commonSx = {
    fontSize: 'sm',
    lineHeight: 'sm',
    fontWeight: 'md',
    display: 'inline-block',
    width: 440,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    ...sx,
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '6px' }}>
        <PostIndicator campaign={post.campaign} hasScheduledTime={!!post.scheduledTime} />
        <Typography component="span" sx={commonSx} {...props}>
          {post?.product?.title}
        </Typography>
      </div>
      {post?.platformData &&
        'caption' in post.platformData &&
        isNotBlank(post?.platformData?.caption) && (
          <Typography
            component="span"
            sx={{
              mt: '2px',
              opacity: 0.8,
              ...commonSx,
            }}
            {...props}
          >
            {post?.platformData?.caption}
          </Typography>
        )}
    </>
  )
}

export default PostTitle
