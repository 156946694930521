import { type FC, useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import useSocialAccount from '../../../hooks/useSocialAccount'
import { useGetCampaignPreviewQuery } from '../../../store/endpoints/postsTemplates'
import { type MediaValue } from '../../../types'
import { POST_DESCRIPTION_DEBOUNCE_TIME } from '../../../utils/constants'
import { isValidSocialType } from '../../../utils/typesHelper'
import PostPreview from '../../views/PostPreview/PostPreview'
import { type CampaignPostPreviewContainerProps } from './CampaignPostPreviewContainer.type'

const CampaignPostPreviewContainer: FC<CampaignPostPreviewContainerProps> = ({
  socialAccountId,
  sourceAccountId,
  postType,
  captionType,
  description,
  collectionIds,
  mediaRule,
}) => {
  const { socialAccount } = useSocialAccount(socialAccountId)

  const [debouncedDescription] = useDebounce(description, POST_DESCRIPTION_DEBOUNCE_TIME)

  const { data: { description: richDescription, media } = {} } = useGetCampaignPreviewQuery(
    {
      template: debouncedDescription ?? '',
      sourceAccount: { id: sourceAccountId, platform: 'SHOPIFY' },
      captionType,
      collectionIds,
      socialPlatform: socialAccount?.platform ?? 'INSTAGRAM',
    },
    {
      skip:
        debouncedDescription === undefined ||
        debouncedDescription === null ||
        collectionIds.length < 1 ||
        debouncedDescription?.length < 3,
    },
  )

  const post = useMemo(() => {
    const notEmptyMedia = media?.filter((item): item is MediaValue => item?.id !== undefined)
    const selectedMedia =
      mediaRule === 'FIRST' && notEmptyMedia?.length !== 0
        ? notEmptyMedia?.slice(0, 1)
        : notEmptyMedia
    return {
      postType,
      media: selectedMedia ?? [],
      description: richDescription ?? description ?? '',
      account: {
        name: socialAccount?.name ?? '',
        url: socialAccount?.picture ?? '',
      },
      socialType: isValidSocialType(socialAccount?.platform) ? socialAccount?.platform : undefined,
    }
  }, [postType, richDescription, description, socialAccount, media, mediaRule])

  return <PostPreview post={post as any} />
}

export default CampaignPostPreviewContainer
