import { yupResolver } from '@hookform/resolvers/yup'
import BoxWithBar from 'COMPONENTS/views/BoxWithBar/BoxWithBar'
import PreviewBox from 'COMPONENTS/views/PreviewBox/PreviewBox'
import { TransparentForm } from 'COMPONENTS/views/TransparentForm/TransparentForm'
import { type FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import CampaignForm from '../CampaignForm/CampaignForm'
import CampaignFormBar from '../CampaignFormBar/CampaignFormBar'
import { campaignsFormSchema } from './CampaignsForm.schema'
import { type CampaignsFormProps, type CampaignsFormValues } from './CampaignsForm.types'

const CampaignsForm: FC<CampaignsFormProps> = ({ defaultValues, onSubmit, renderPreview }) => {
  const { control, handleSubmit, watch, formState, ...formRest } = useForm<CampaignsFormValues>({
    defaultValues,
    resolver: yupResolver<CampaignsFormValues>(campaignsFormSchema),
  })

  const campaignsForms = watch('campaigns')

  return (
    <TransparentForm onSubmit={handleSubmit(onSubmit)}>
      <FormProvider
        watch={watch}
        control={control}
        handleSubmit={handleSubmit}
        formState={formState}
        {...formRest}
      >
        <BoxWithBar bar={<CampaignFormBar />}>
          {!formState.isLoading && (
            <PreviewBox preview={<>{renderPreview?.(campaignsForms[0])}</>}>
              <CampaignForm />
            </PreviewBox>
          )}
        </BoxWithBar>
      </FormProvider>
    </TransparentForm>
  )
}

export default CampaignsForm
