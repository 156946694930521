import type { ConfirmationOptions } from 'HOOKS/useConfirm/useConfirm.types'

import { CAMPAIGN_STATUSES, type CampaignStatus, type PageCampaignResponse } from '@/types'

import { type CampaignFilter } from './components/CampaignsFilter/types'

export const getCampaignStatus = (params: URLSearchParams): CampaignStatus => {
  const status = params.get('status')?.toUpperCase()

  switch (status) {
    case CAMPAIGN_STATUSES.PAUSED:
    case CAMPAIGN_STATUSES.ACTIVE:
      return status
    default:
      return CAMPAIGN_STATUSES.ACTIVE
  }
}

export const getCampaignFilter = (params: URLSearchParams): CampaignFilter => {
  const result: CampaignFilter = {}

  const search = params.get('search') ?? ''
  const socialAccountId = params.get('social') ?? ''

  if (search !== '') result.search = search
  if (socialAccountId !== '') result.socialAccountId = socialAccountId

  return result
}

export const getPage = (params: URLSearchParams): number => {
  const page = Number(params.get('page'))
  return Number.isFinite(page) && page !== 0 ? page : 1
}

export const getNormalizedParams = (params: URLSearchParams): URLSearchParams => {
  const newParams = new URLSearchParams()

  const search = params.get('search') ?? ''
  const socialAccountId = params.get('social') ?? ''

  newParams.append('page', (getPage(params) - 1).toString())
  newParams.append('status', getCampaignStatus(params))

  if (search !== '') newParams.append('search', search)
  if (socialAccountId !== '') newParams.append('accountId', socialAccountId)

  return newParams
}

export const getNameOfCampaigns = (
  campaignPage: PageCampaignResponse,
  selectedIds: string[],
): string[] => {
  return selectedIds.map((id) => {
    const campaign = campaignPage.content?.find((campaign) => campaign.id === id)
    return campaign?.name ?? ''
  })
}

export const getDeleteConfirmationTitle = (
  campaignPage: PageCampaignResponse,
  selectedIds: string[],
): string => {
  const selectedIdsLength = selectedIds.length

  if (selectedIdsLength === 0) {
    return 'Delete 0 campaigns'
  }

  if (selectedIdsLength > 1) {
    return `Delete ${selectedIdsLength} campaigns?`
  }

  const campaignNames = getNameOfCampaigns(campaignPage, selectedIds)

  return `Delete "${campaignNames[0]}"?`
}

export const getDeleteConfirmationOptions = (
  campaignPage: PageCampaignResponse,
  selectedIds: string[],
): ConfirmationOptions => {
  const title = getDeleteConfirmationTitle(campaignPage, selectedIds)

  return {
    title,
    question: 'Deleting a campaign will NOT delete any posts already published by this campaign.',
    confirm: 'Delete',
  }
}
