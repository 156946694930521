import PostPostPreviewContainer from 'COMPONENTS/forms/PostPostPreviewContainer/PostPostPreviewContainer'
import useTimeScheduler from 'HOOKS/useTimeScheduler/useTimeScheduler'
import { type BaseSyntheticEvent, type FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import api from 'STORE/api'
import {
  useCreateFacebookPostMutation,
  useCreateInstagramPostMutation,
  useEditFacebookPostMutation,
  useEditInstagramPostMutation,
  useMovePostToDraftMutation,
  useSendPostMutation,
} from 'STORE/endpoints/posts'
import { useLazyProductQuery } from 'STORE/endpoints/shopify'
import { trackId } from 'STORE/posts/actions'

import { type AppDispatch } from '@/store'

import { usePostStatus } from '../../hooks/usePostStatus'
import { type PostFormValues } from './components/PostsForm/components/PostForm/PostForm.types'
import PostsForm from './components/PostsForm/PostsForm'
import { useEnrichText } from './PostFormContainer.hooks'
import { createPostBody, getPostStatusAfterSubmit, getSubmitType } from './PostFormContainer.utils'
import { type PostsFormContainerProps, type SubmitOptions } from './PostsFormContainer.types'

const PostsFormContainer: FC<PostsFormContainerProps> = ({
  socialAccounts,
  initPostFormValues,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const scheduleTime = useTimeScheduler()
  const status = usePostStatus()

  const [createInstagramPost] = useCreateInstagramPostMutation()
  const [createFacebookPost] = useCreateFacebookPostMutation()
  const [editInstagramPost] = useEditInstagramPostMutation()
  const [editFacebookPost] = useEditFacebookPostMutation()
  const [movePostToDraft] = useMovePostToDraftMutation()
  const [sendPost] = useSendPostMutation()
  const [getProduct] = useLazyProductQuery()
  const { enrichText } = useEnrichText()

  const submitters =
    status === 'NEW'
      ? {
          FACEBOOK: createFacebookPost,
          INSTAGRAM: createInstagramPost,
          TIKTOK: createInstagramPost,
        }
      : {
          FACEBOOK: editFacebookPost,
          INSTAGRAM: editInstagramPost,
          TIKTOK: createInstagramPost,
        }

  const submitPost = async (
    postFormValues: Required<PostFormValues>,
    { isDraft, scheduledTime, submitType }: SubmitOptions,
  ): Promise<void> => {
    const product = await getProduct({
      sourceAccountId: postFormValues.sourceAccountId,
      productId: postFormValues.productId,
    })

    const richCaption = await enrichText(postFormValues.caption ?? '', product.data)

    const transition = `${status}->${submitType}`
    let result

    switch (transition) {
      case 'SCHEDULED->draft':
        result = await movePostToDraft(postFormValues.postId)
        break
      case 'DRAFT->instant':
      case 'SCHEDULED->instant':
        result = await sendPost({
          body: createPostBody(postFormValues, richCaption, scheduledTime),
        })
        break
      case 'NEW->instant':
      case 'NEW->draft':
      case 'NEW->schedule':
      default:
        result = await submitters[postFormValues.socialName]({
          draft: isDraft,
          body: createPostBody(postFormValues, richCaption, scheduledTime),
        })
        break
    }

    if ('error' in result && 'data' in result.error) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw result.error
    }

    if ('data' in result && result.data.id) {
      if (isDraft === false && scheduledTime === undefined) {
        dispatch(trackId(result.data?.id))
      }
    }
  }

  const handleSubmit = async (post: PostFormValues, event?: BaseSyntheticEvent): Promise<void> => {
    event?.stopPropagation()

    const submitType = getSubmitType(event)
    const options: SubmitOptions = {
      isDraft: submitType === 'draft',
      submitType,
    }

    try {
      if (submitType === 'schedule') {
        const scheduledTime = await scheduleTime()
        await submitPost(post as Required<PostFormValues>, { ...options, scheduledTime })
      } else {
        await submitPost(post as Required<PostFormValues>, options)
      }

      navigate(`posts?status=${getPostStatusAfterSubmit(submitType)}`)

      // todo: fix by add cache invalidation for specigitfic tag
      dispatch(api.util.resetApiState())
    } catch (error) {}
  }

  return (
    <PostsForm
      socialAccounts={socialAccounts}
      defaultValues={initPostFormValues}
      onSubmit={handleSubmit}
      renderPostFormPreview={(values) => (
        <PostPostPreviewContainer
          socialAccountId={values.socialAccountId ?? ''}
          postType={values.postType ?? 'POST'}
          socialType={values.socialName ?? 'INSTAGRAM'}
          media={values.media ?? []}
          sourceAccountId={values.sourceAccountId}
          productId={values.productId}
          description={values.caption ?? ''}
        />
      )}
    />
  )
}

export default PostsFormContainer
