import { Box, FormControl, Typography } from '@mui/joy'
import { CollectionsMultiSelect } from 'COMPONENTS/forms'
import ErrorMessage from 'COMPONENTS/forms/ErrorMessage/ErrorMessage'
import { type FC } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'

import { LABEL_SX } from '../constants'
import { type ControlProps } from '../types'

export interface CollectionsControlProps extends ControlProps {
  sourceAccountId: string
}

export const CollectionsControl: FC<CollectionsControlProps> = ({ name, sourceAccountId }) => {
  const { control } = useFormContext()

  const {
    fieldState: { error: collectionIdsError },
  } = useController({ control, name })

  return (
    <FormControl>
      <Box>
        <Typography sx={LABEL_SX}>Collection</Typography>
        <Box sx={{ flex: 2 }}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <CollectionsMultiSelect
                  disabled={
                    sourceAccountId === undefined ||
                    sourceAccountId === null ||
                    sourceAccountId === ''
                  }
                  color={collectionIdsError !== undefined ? 'danger' : 'neutral'}
                  sourceAccountId={sourceAccountId}
                  value={field.value ?? []}
                  onChange={(_, newValue) => {
                    field.onChange(newValue)
                  }}
                  multiple
                  placeholder="Choose collection"
                />
              )
            }}
          />

          <ErrorMessage error={collectionIdsError} />
        </Box>
      </Box>
    </FormControl>
  )
}
