import { type FC } from 'react'
import { type IconBaseProps } from 'react-icons'
import { TbFreezeRow, TbHistoryToggle, TbSquarePlus } from 'react-icons/tb'

import { type PostType } from '@/types'

interface PostTypeIconProps extends IconBaseProps {
  postType?: PostType
}

const PostTypeIcon: FC<PostTypeIconProps> = ({ postType, ...props }) => {
  switch (postType) {
    case 'REEL':
      return <TbFreezeRow {...props} />
    case 'POST':
      return <TbSquarePlus {...props} />
    case 'STORY':
      return <TbHistoryToggle {...props} />
    default:
      return null
  }
}

export default PostTypeIcon
