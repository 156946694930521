import Skeleton from '@mui/joy/Skeleton'
import { type FC, useCallback } from 'react'

import useConfirm from '../../../../hooks/useConfirm/useConfirm'
import useSocialAccounts, { type NotEmptySocialAccount } from '../../../../hooks/useSocialAccounts'
import { useDeleteSocialAccountMutation } from '../../../../store/endpoints/social'
import BusinessAccountRequiredAlert from './components/BusinessAccountRequiredAlert'
import SocialAccounts from './components/SocialsAccounts'

const SocialAccountsContainer: FC = () => {
  const [deleteSocialAccount] = useDeleteSocialAccountMutation()
  const { socialAccounts, isLoading: isSocialAccountsLoading } = useSocialAccounts()

  const confirm = useConfirm()

  const handleDeleteSocialAccountClick = useCallback(
    async (socialAccount: NotEmptySocialAccount): Promise<void> => {
      const accountName = socialAccount.name ?? 'account'

      const isConfirmed = await confirm({
        title: 'Delete confirmation',
        question: `Do you really want to delete ${accountName}?`,
        confirm: `Delete ${accountName}`,
      })

      if (!isConfirmed) return

      await deleteSocialAccount({
        id: socialAccount.id,
        platform: socialAccount.platform,
      })
    },
    [],
  )

  return (
    <>
      <BusinessAccountRequiredAlert />

      {isSocialAccountsLoading && <Skeleton variant="rectangular" height={200} />}

      {Array.isArray(socialAccounts) && socialAccounts.length !== 0 && (
        <SocialAccounts
          socialAccounts={socialAccounts}
          onDeleteClick={handleDeleteSocialAccountClick}
        />
      )}
    </>
  )
}

export default SocialAccountsContainer
