import Button from '@mui/joy/Button'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'

import PageWithBackground from '../../components/views/PageWithBackground/PageWithBackground'
import { useLoginDevMutation } from '../../store/endpoints/auth'

const ExpiredPage: FC = () => {
  const [devLogin, { isLoading }] = useLoginDevMutation()
  const navigate = useNavigate()

  const handleDevLoginClick = async (): Promise<void> => {
    await devLogin('970702e5-7377-4c03-bc31-029ccc8db202')
    navigate('/')
  }

  return (
    <PageWithBackground>
      <Stack gap={4} sx={{ mb: 2 }}>
        <Stack gap={1}>
          <svg
            width="51"
            height="52"
            viewBox="0 0 51 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 25.0002H25.8016V17.2004L31 11H10.1984V18.7998L5 25.0002Z"
              stroke="#171A1C"
              strokeWidth="4.00006"
            />
            <g filter="url(#filter0_d_2246_3286)">
              <rect x="20.1431" y="17.5715" width="24" height="24" rx="12" fill="#F0F4F8" />
              <path
                d="M32.1425 25.2857V29.5715L34.7139 32.1429M24.4282 29.5715C24.4282 30.5845 24.6278 31.5877 25.0154 32.5236C25.4031 33.4595 25.9713 34.3099 26.6877 35.0263C27.404 35.7426 28.2544 36.3109 29.1904 36.6985C30.1263 37.0862 31.1295 37.2857 32.1425 37.2857C33.1556 37.2857 34.1587 37.0862 35.0946 36.6985C36.0306 36.3109 36.881 35.7426 37.5973 35.0263C38.3137 34.3099 38.8819 33.4595 39.2696 32.5236C39.6573 31.5877 39.8568 30.5845 39.8568 29.5715C39.8568 27.5255 39.044 25.5633 37.5973 24.1166C36.1506 22.6699 34.1885 21.8572 32.1425 21.8572C30.0966 21.8572 28.1344 22.6699 26.6877 24.1166C25.241 25.5633 24.4282 27.5255 24.4282 29.5715Z"
                stroke="#4564EB"
                strokeWidth="1.71429"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_2246_3286"
                x="13.2859"
                y="14.143"
                width="37.7143"
                height="37.7143"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="3.42857" />
                <feGaussianBlur stdDeviation="3.42857" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_2246_3286"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_2246_3286"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>

          <Typography level="h3">Your session has expired</Typography>
          <Typography level="body-sm">
            If you want to continue please sign in with Shopify.
          </Typography>
        </Stack>
        <Link href="https://www.shopify.com/login" underline="none">
          <Button fullWidth>Sign in with Shopify</Button>
        </Link>
        {import.meta.env.DEV && (
          <Button fullWidth onClick={handleDevLoginClick} loading={isLoading}>
            Dev login
          </Button>
        )}
      </Stack>
    </PageWithBackground>
  )
}

export default ExpiredPage
