import Box from '@mui/joy/Box'
import Textarea from '@mui/joy/Textarea'
import { AIDescriptionTools } from 'COMPONENTS/ai'
import { type FC } from 'react'

export const CaptionTools: FC<{
  value: string
  onChange: (value: string) => void
  productId?: string | null
  sourceAccountId?: string | null
}> = ({ value, onChange, productId, sourceAccountId }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', zIndex: 1, p: '12px' }}>
        <AIDescriptionTools
          productId={productId ?? ''}
          sourceAccountId={sourceAccountId ?? ''}
          accountPlatform={'FACEBOOK'}
          description={value}
          onChange={onChange}
        />
      </Box>
      <Textarea
        sx={{ pt: 6, minWidth: 484 }}
        minRows={3}
        placeholder="Enter or generate caption..."
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      />
    </Box>
  )
}
