import FormControl from '@mui/joy/FormControl'
import Typography from '@mui/joy/Typography'
import SocialAccountSelectV2 from 'COMPONENTS/forms/SocialAccountSelectV2/SocialAccountSelectV2'
import SocialAccountAvatar from 'COMPONENTS/views/SocialAccountAvatar/SocialAccountAvatar'
import type { NotEmptySocialAccount } from 'HOOKS/useSocialAccounts'
import type { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { PostFormField } from '../../PostForm.types'
import { labelsSx } from '../../styles'

interface PageControlProps {
  socialAccounts: NotEmptySocialAccount[]
}

export const PageControl: FC<PageControlProps> = ({ socialAccounts }) => {
  const { setValue, control } = useFormContext()

  return (
    <FormControl>
      <Typography sx={labelsSx} id="post-title-label" component="label">
        Page
      </Typography>
      <Controller
        name={PostFormField.SocialAccountId}
        control={control}
        render={({ field }) => {
          const socialAccount = socialAccounts.find((acc) => acc.id === field.value)
          return (
            <SocialAccountSelectV2
              sx={{ flex: 1 }}
              placeholder="Choose page"
              onChange={(_, newValue) => {
                field.onChange(newValue)
                const socialAccount = socialAccounts.find((account) => account.id === newValue)
                setValue(PostFormField.SocialName, socialAccount?.platform)
              }}
              value={field.value}
              startDecorator={
                socialAccount ? (
                  <SocialAccountAvatar size="sm" socialAccount={socialAccount} badgeSize={12} />
                ) : null
              }
            />
          )
        }}
      />
    </FormControl>
  )
}
