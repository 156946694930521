import Box from '@mui/joy/Box'
import Dropdown from '@mui/joy/Dropdown'
import IconButton from '@mui/joy/IconButton'
import Link from '@mui/joy/Link'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import MenuButton from '@mui/joy/MenuButton'
import Sheet from '@mui/joy/Sheet'
import Tooltip from '@mui/joy/Tooltip'
import type { ReactElement } from 'react'
import { TbCalendarRepeat, TbHandStop, TbHelp, TbSettings, TbSquarePlus } from 'react-icons/tb'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'

import LogoIcon from '../components/views/LogoIcon/LogoIcon'

interface MenuItem {
  text: string
  icon: ReactElement | string
  path?: string
  href?: string
  title?: string
}

const topMenu: MenuItem[] = [
  { text: 'Posts', icon: <TbSquarePlus />, path: 'posts?status=SCHEDULED', title: 'Posts' },
  {
    text: 'Campaigns',
    icon: <TbCalendarRepeat />,
    path: 'campaigns?status=ACTIVE',
    title: 'Campaigns',
  },
]
const bottomMenu: MenuItem[] = [
  {
    text: 'FeatureRequest',
    icon: <TbHandStop />,
    href: 'https://amplorium.canny.io/features-requests',
    title: 'Ask feature',
  },
  {
    text: 'HelpDesk',
    icon: <TbHelp />,
    href: 'https://help.amplorium.com',
    title: 'Help center',
  },
  { text: 'Setting', icon: <TbSettings />, path: 'settings', title: 'Settings' },
]

const HeaderItem = ({ icon, path, href, title }: MenuItem): ReactElement => {
  const location = useLocation()
  const navigate = useNavigate()

  const basePathname = location.pathname.split('/')[1]

  const menuButton = (
    <ListItem key={title} sx={{ p: 0 }}>
      <Dropdown>
        <Tooltip variant="solid" placement="right" arrow title={title}>
          <MenuButton
            sx={{
              bgcolor:
                path !== undefined && basePathname !== undefined && path.startsWith(basePathname[0])
                  ? 'neutral.plainActiveBg'
                  : undefined,
              color: 'text.icon',
              ':hover': {
                bgcolor: 'neutral.plainHoverBg',
                color: 'text.icon',
              },
            }}
            slots={{ root: IconButton }}
            slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'xs' } }}
            variant="outlined"
            onClick={
              path !== undefined
                ? () => {
                    navigate(path)
                  }
                : undefined
            }
          >
            {icon}
          </MenuButton>
        </Tooltip>
      </Dropdown>
    </ListItem>
  )

  if (href !== undefined) {
    return (
      <Link key={title} href={href} target="_blank">
        {menuButton}
      </Link>
    )
  }

  if (path !== undefined) {
    return (
      <RouterLink key={title} to={`/${path}`}>
        {menuButton}
      </RouterLink>
    )
  }

  return menuButton
}

const Header = (): ReactElement => {
  return (
    <Sheet
      sx={{
        pt: '21px',
        px: 2,
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
        flexShrink: 0,
        gap: 3,
        width: 68,
      }}
    >
      <RouterLink to="/">
        <LogoIcon />
      </RouterLink>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          gap: 3,
        }}
      >
        <List key="top" sx={{ p: 0, display: 'flex', flexGrow: 1, gap: 2 }}>
          {topMenu.map(HeaderItem)}
        </List>
        <List key="bottom" sx={{ p: 0, flexGrow: 0, gap: 2 }}>
          {bottomMenu.map(HeaderItem)}
        </List>
      </Box>
    </Sheet>
  )
}

export default Header
