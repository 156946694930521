import Button from '@mui/joy/Button'
import type { FC, PropsWithChildren } from 'react'

import { useConnectSocialAccountMutation } from '../../../../store/endpoints/social'

// TODO: Rename to more generic name what will support multiple social platforms
const FacebookButtonContainer: FC<PropsWithChildren> = ({ children }) => {
  const [connectSocialAccount, { isLoading }] = useConnectSocialAccountMutation()

  const handleSocialConnect = (platform: string) => async (): Promise<void> => {
    const response = await connectSocialAccount({ platform }).unwrap()

    if (response.redirectUrl) {
      window.location.href = response.redirectUrl
    }
  }

  return (
    <Button variant="outlined" onClick={handleSocialConnect('FACEBOOK')} loading={isLoading}>
      {children}
    </Button>
  )
}

export default FacebookButtonContainer
