/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { type OAuthRedirectResponse } from '../../types'
import api from '../api'

const socialApi = api.injectEndpoints({
  endpoints: (builder) => ({
    connectSocialAccount: builder.mutation<OAuthRedirectResponse, { platform: string }>({
      query: ({ platform }) => ({
        url: `api/social/${platform}/connect`,
        method: 'GET',
      }),
      invalidatesTags: ['User'],
    }),
    deleteSocialAccount: builder.mutation<void, { platform: string; id: string }>({
      query: ({ platform, id }) => ({
        url: `api/social/${platform}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
  }),
})

export const { useConnectSocialAccountMutation, useDeleteSocialAccountMutation } = socialApi

export default socialApi
