import type { ConfirmationOptions } from 'HOOKS/useConfirm/useConfirm.types'

import type { PagePostResponse } from '@/types'

export const normalizePostsFilterParams = (
  params: Record<string, string | number | undefined | null | string[]>,
): URLSearchParams => {
  const urlSearchParams = new URLSearchParams()

  for (const key in params) {
    if (
      params[key] === undefined ||
      params[key] === null ||
      params[key] === '' ||
      (params[key] as string[] | undefined)?.length === 0
    ) {
      continue
    } else {
      if (typeof params[key] === 'string') {
        urlSearchParams.append(key, params[key])
      } else if (typeof params[key] === 'number') {
        urlSearchParams.append(key, `${params[key]}`)
      } else if (Array.isArray(params[key])) {
        params[key].forEach((value) => {
          urlSearchParams.append(key, value)
        })
      }
    }
  }

  return urlSearchParams
}

// const normalizeRequest = (request: InstagramPostCreateRequest): InstagramPostCreateRequest => {
//   if (request.scheduledTime === '') {
//     delete request.scheduledTime
//   }

//   return request
// }

// export const toRequest = ({
//   media: medias,
//   product: productId,
//   scheduledTime,
//   postType,
//   social,
//   account,
//   ...rest
// }: Post): InstagramPostCreateRequest =>
//   normalizeRequest({
//     media: medias.map(({ id }) => ({ id })), // remove url
//     product: { id: productId, title: '' },
//     platformData: {
//       postType,
//       caption: 'description' in rest ? rest.description : '',
//       // hashtags: 'hashtags' in rest ? rest.hashtags.match(/#[a-z]+/gi) ?? [] : [],
//     },
//     socialAccount: {
//       id: social,
//       platform: 'INSTAGRAM',
//     },
//     scheduledTime,
//   })

export const capitalizeFirstLetter = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1)

// export const toForm = (post: PostResponse): Post => ({
//   media:
//     post.media?.reduce<MediaValue[]>((notEmptyMedia, { id, url }) => {
//       if (id !== undefined) {
//         notEmptyMedia.push({ id, url })
//       }
//       return notEmptyMedia
//     }, []) ?? [],
//   scheduledTime: post.scheduledTime,
//   postType: post.postType ?? ('POST' as any),
//   description: post.platformData?.caption ?? '',
//   hashtags: '',
//   product: post.product?.id ?? '',
//   account: post?.sourceAccount?.id ?? '',
//   social: post?.socialAccount?.id ?? '',
// })

export const getPostNames = (postPage: PagePostResponse, selectedIds: string[]): string[] => {
  return selectedIds.map((id) => {
    const post = postPage.content?.find((post) => post.id === id)
    return post?.product?.title ?? ''
  })
}

const getDeleteConfirmationTitle = (postPage: PagePostResponse, selectedIds: string[]): string => {
  const selectedIdsLength = selectedIds.length

  if (selectedIdsLength === 0) {
    return 'Delete 0 posts'
  }

  if (selectedIdsLength > 1) {
    return `Delete ${selectedIdsLength} posts?`
  }

  const postNames = getPostNames(postPage, selectedIds)

  return `Delete "${postNames[0]}"?`
}

export const getDeleteConfirmationOptions = (
  postPage: PagePostResponse,
  selectedIds: string[],
): ConfirmationOptions => {
  const title = getDeleteConfirmationTitle(postPage, selectedIds)

  return {
    title,
    question: `By deleting posts in Amplorium, it will NOT be removed from the social network. You may need to delete it manually on the platform.`,
    confirm: `Delete`,
  }
}

export const getSuccessDeleteConfirmationMessage = (length: number): string => {
  if (length > 1) {
    return 'Posts are successfully deleted'
  }

  return 'The post is successfully deleted'
}
