import { createContext, type FC, type PropsWithChildren } from 'react'

import { useUserQuery } from '../../../../store/endpoints/root'

export const TimezoneContext = createContext<string | undefined>(undefined)

export const TimezoneProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: user } = useUserQuery()

  return <TimezoneContext.Provider value={user?.timezone}>{children}</TimezoneContext.Provider>
}
