import { v4 as uuidv4 } from 'uuid'

import { type MediaValue, type PostResponse, type UserResponse } from '../../../../../../types'
import { type PostFormValues } from './components/PostForm/PostForm.types'

export const initializePostsFormByPost = (
  post: PostResponse,
  id?: string,
): Partial<PostFormValues> => {
  const media = post.media
    ?.filter((media): media is MediaValue => media.id !== undefined)
    .map((media) => ({ ...media, frontId: media.id ?? uuidv4() }))

  const map = new Map<keyof PostFormValues, PostFormValues[keyof PostFormValues]>()

  map.set('socialName', post?.socialAccount?.platform ?? 'INSTAGRAM')
  map.set('postType', post?.postType)
  map.set('sourceAccountId', post?.sourceAccount?.id)
  map.set('socialAccountId', post?.socialAccount?.id)
  map.set('productId', post?.product?.id)
  map.set('media', media)
  map.set('postId', id)

  if (post?.platformData && 'caption' in post.platformData) {
    map.set('caption', post.platformData.caption)
  }

  for (const [key, value] of map) {
    if (value === null || value === undefined) {
      map.delete(key)
    }
  }

  return Object.fromEntries(map)
}

export const initializePostsFormByUser = (user: UserResponse): Partial<PostFormValues> => {
  const sourceAccount =
    user?.sourceAccounts?.length === 1 ? user?.sourceAccounts?.[0]?.id : undefined

  const someInstagramSocialAccount = user?.socialAccounts?.find(
    (socialAccount) => socialAccount?.platform === 'INSTAGRAM',
  )

  const socialAccount =
    someInstagramSocialAccount === undefined
      ? user?.socialAccounts?.length === 1
        ? user?.socialAccounts?.[0]
        : undefined
      : someInstagramSocialAccount

  return {
    socialName:
      (socialAccount?.platform !== 'TIKTOK' ? socialAccount?.platform : undefined) ?? 'INSTAGRAM',
    postType: 'POST',
    socialAccountId: socialAccount?.id ?? '',
    sourceAccountId: sourceAccount,
    media: [],
  }
}
