import Typography, { type TypographyProps } from '@mui/joy/Typography'
import { type FC } from 'react'

interface DatePipeProps extends TypographyProps {
  date: Date | string | number
}

const DatePipe: FC<DatePipeProps> = ({ date: dateAsAny, sx, ...props }) => {
  const commonSx = {
    width: 120,

    fontSize: 'sm',
    lineHeight: 'sm',
    fontWeight: 'md',
    ...sx,
  }

  const date =
    typeof dateAsAny === 'string' || typeof dateAsAny === 'number' ? new Date(dateAsAny) : dateAsAny

  return (
    <>
      <Typography sx={commonSx} component="span" {...props}>
        {date.toLocaleDateString(undefined, {
          month: 'short',
          year: 'numeric',
          day: 'numeric',
        })}
      </Typography>
      <Typography sx={{ mt: '2px', opacity: 0.8, ...commonSx }} component="span" {...props}>
        {date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
      </Typography>
    </>
  )
}

export default DatePipe
