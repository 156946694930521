import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import DialogActions from '@mui/joy/DialogActions'
import DialogContent from '@mui/joy/DialogContent'
import Link from '@mui/joy/Link'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import Sheet from '@mui/joy/Sheet'
import { type FC, useState } from 'react'
import { TbExternalLink } from 'react-icons/tb'

import PostPostPreviewContainer from '../../../../components/forms/PostPostPreviewContainer/PostPostPreviewContainer'
import { useAppDispatch } from '../../../../store'
import { pushErrorNotification } from '../../../../store/notifications/actions'
import { type PostResponse, type SocialType } from '../../../../types'
import { type PostTableProps } from '../../types'
import BasePostsTable from '../base/BasePostsTable'

const SentPostsTable: FC<PostTableProps> = (props) => {
  const [post, setPost] = useState<PostResponse | null>(null)
  const dispatch = useAppDispatch()

  const handleClosePostPreview = (): void => {
    setPost(null)
  }

  const handleRowClick = (post: PostResponse): void => {
    if (post) {
      setPost(post)
    } else {
      dispatch(pushErrorNotification({ message: "Can't open post, post link is empty" }))
    }
  }
  return (
    <>
      <BasePostsTable {...props} onRowClick={handleRowClick} />
      <Modal open={!!post} onClose={handleClosePostPreview}>
        <ModalDialog variant="plain">
          <Box display="flex">
            <ModalClose />
          </Box>
          <DialogContent sx={{ position: 'relative', padding: '0 14px' }}>
            {post?.socialAccount?.id && (
              <PostPostPreviewContainer
                description={
                  post.platformData && 'caption' in post.platformData && post.platformData?.caption
                    ? post.platformData?.caption
                    : ''
                }
                media={(post.media ?? []) as any}
                postType={post?.postType ?? 'POST'}
                socialAccountId={post?.socialAccount?.id}
                socialType={(post?.postType ?? 'INSTAGRAM') as SocialType}
                sourceAccountId={post?.sourceAccount?.id}
              />
            )}

            <DialogActions
              sx={{
                position: 'sticky',
                bottom: 16,
                right: 0,
                left: 0,
                zIndex: 2,
              }}
            >
              <Sheet
                sx={{
                  p: 2,
                  width: '100%',
                  borderRadius: 'sm',
                  overflow: 'hidden',
                  position: 'relative',
                  background: 'rgba(221, 231, 238, 0.30)',
                  backdropFilter: 'blur(5px)',
                }}
                variant="outlined"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ position: 'sticky', bottom: 16, right: 0, left: 0 }}
                >
                  <Button variant="outlined" onClick={handleClosePostPreview}>
                    Cancel
                  </Button>
                  <Link href={post?.postLink} target="_blank" underline="none">
                    <Button startDecorator={<TbExternalLink />} content="a" href={post?.postLink}>
                      Visit post
                    </Button>
                  </Link>
                </Box>
              </Sheet>
            </DialogActions>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  )
}

export default SentPostsTable
