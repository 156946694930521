import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Typography from '@mui/joy/Typography'
import { type FC, memo } from 'react'
import { TbTrash } from 'react-icons/tb'

interface ContextBarProps {
  selectedAmount: number
  onRemoveClick: () => void
  isRemoving: boolean
  label: string
}

const BaseContextBar: FC<ContextBarProps> = ({
  selectedAmount,
  isRemoving,
  onRemoveClick,
  label,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        py: 2.5,
        px: 2,
        gap: 3,
        alignItems: 'center',
        height: 80,
      }}
    >
      <Typography fontWeight={600}>
        <span>{selectedAmount.toString()} </span>
        <span>{label}</span>
      </Typography>
      <Button
        sx={{ ml: 'auto' }}
        color="neutral"
        size="md"
        variant="outlined"
        loading={isRemoving}
        disabled={isRemoving}
        startDecorator={<TbTrash size={24} />}
        onClick={onRemoveClick}
      >
        Delete
      </Button>
    </Box>
  )
}

export default memo(BaseContextBar)
