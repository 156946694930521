import Alert from '@mui/joy/Alert'
import FormControl from '@mui/joy/FormControl'
import RadioGroup from '@mui/joy/RadioGroup'
import Typography from '@mui/joy/Typography'
import { type ChangeEvent, type FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TbInfoSquareRounded, TbLayersSubtract, TbPhoto } from 'react-icons/tb'
import Radio from 'UI/Radio/Radio'

import { LABEL_SX } from '../constants'
import { type ControlProps } from '../types'

export const MediaRuleControl: FC<ControlProps> = ({ name }) => {
  const { control } = useFormContext()

  return (
    <FormControl>
      <Typography sx={LABEL_SX} id="post-title-label" component="label">
        Include media
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup
            value={field.value ?? ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              field.onChange(event.target.value)
            }}
            variant="outlined"
            orientation="horizontal"
          >
            <Radio
              overlay
              disableIcon
              value="FIRST"
              label={
                <>
                  <TbPhoto />
                  <Typography>Thumbnail image</Typography>
                </>
              }
            />
            <Radio
              overlay
              value="ALL"
              disableIcon
              label={
                <>
                  <TbLayersSubtract />
                  <Typography>Multiple images</Typography>
                </>
              }
            />
          </RadioGroup>
        )}
      />

      <Alert sx={{ mt: 1 }} startDecorator={<TbInfoSquareRounded />}>
        Alternate your post captions by creating up 5 different variants
      </Alert>
    </FormControl>
  )
}
