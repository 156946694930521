import {
  type CaptionType,
  type Day,
  type MediaRule,
  type PostType,
  type SelectionType,
  type SocialType,
} from 'TYPES/index'

export interface CampaignFormValues {
  socialName: Exclude<SocialType, 'PINTEREST'>
  socialAccountId: string

  name: string
  type: SelectionType
  productRuleType: string
  sourceAccountId: string
  collectionIds?: string[]
  tags?: string[]

  postType: PostType
  mediaRule: MediaRule
  captionType: CaptionType
  specialInstruction?: string
  postTemplates?: string[]

  time: string
  days: Day[]
}

export enum CampaignFormFields {
  SocialAccountId = 'campaigns.0.socialAccountId',
  SocialName = 'campaigns.0.socialName',
  Name = 'campaigns.0.name',
  SourceAccountId = 'campaigns.0.sourceAccountId',
  Type = 'campaigns.0.type',
  ProductRuleType = 'campaigns.0.productRuleType',
  CollectionIds = 'campaigns.0.collectionIds',
  Tags = 'campaigns.0.tags',
  PostType = 'campaigns.0.postType',
  MediaRule = 'campaigns.0.mediaRule',
  CaptionType = 'campaigns.0.captionType',
  PostTemplates = 'campaigns.0.postTemplates',
  SpecialInstruction = 'campaigns.0.specialInstruction',
  Time = 'campaigns.0.time',
  Days = 'campaigns.0.days',
}

export interface CampaignFormProps {
  renderPreview?: (values: unknown) => void
}
