import 'moment-timezone'

import { Box, Button } from '@mui/joy'
import Card from '@mui/joy/Card'
import CardActions from '@mui/joy/CardActions'
import CardContent from '@mui/joy/CardContent'
import Modal, { type ModalProps } from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import Typography from '@mui/joy/Typography'
import { StaticDatePicker, TimeField } from '@mui/x-date-pickers'
import moment, { type Moment } from 'moment'
import { type FC, useRef } from 'react'

export interface TimeSchedulerModalProps extends Omit<ModalProps, 'children'> {
  onTimeConfirmed: (date: string) => void
  onDecline: () => void
  timezone?: string
}

export const TimeSchedulerModal: FC<TimeSchedulerModalProps> = ({
  onTimeConfirmed,
  onDecline,
  timezone = 'America/New_York',
  sx,
  ...props
}) => {
  const value = useRef<Moment>(moment.tz(timezone))
  // const [value, setValue] = useState<Moment>(moment.tz('America/New_York'))

  const handleConfirm = (): void => {
    onTimeConfirmed(value.current.format('YYYY-MM-DDTHH:mm:ss'))
  }

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...props}
    >
      <Card
        sx={{
          width: 360,
          display: 'flex',
          alignItems: 'flex-start',
          padding: 2.5,
        }}
      >
        <ModalClose />
        <CardContent sx={{ mt: 3 }}>
          <Box display="flex" alignItems="flex-start" flexDirection="column" sx={{ gap: 1 }}>
            <StaticDatePicker
              value={value.current}
              onChange={(a) => {
                if (a !== null) {
                  value.current = a as any
                }
              }}
              showDaysOutsideCurrentMonth
              displayStaticWrapperAs="desktop"
            />
            <Typography level="title-sm">Time</Typography>
            <TimeField
              value={value.current}
              onChange={(a) => {
                if (a !== null) {
                  value.current = a as any
                }
              }}
              fullWidth
              size="small"
              timezone={timezone}
            />
            <Typography level="title-sm" sx={{ marginTop: 2 }}>
              Timezone
            </Typography>
            <Typography level="body-sm">{timezone}</Typography>
          </Box>
        </CardContent>
        <CardActions sx={{ gap: 2, width: '100%', justifyContent: 'flex-end' }}>
          <Button size="sm" variant="outlined" onClick={onDecline}>
            Cancel
          </Button>
          <Button size="sm" onClick={handleConfirm}>
            Confirm
          </Button>
        </CardActions>
      </Card>
    </Modal>
  )
}
