import CampaignPostPreviewContainer from 'COMPONENTS/forms/CampaignPostPreviewContainer/CampaignPostPreviewContainer'
import { type FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import PostsFormSkeleton from '../../components/skeletons/PostsFormSkeleton/PostsFormSkeleton'
import RootBox from '../../components/views/RootBox/RootBox'
import useSocialAccounts from '../../hooks/useSocialAccounts'
import useSourceAccounts from '../../hooks/useSourceAccounts'
import { useCampaignQuery } from '../../store/endpoints/campaign'
import { useUserQuery } from '../../store/endpoints/root'
import CampaignsFormSubmitter from './components/CampaignFormSubmitter/CampaignsFormSubmitter'
import { getDefaultCampaign } from './components/CampaignFormSubmitter/CampaignsFormSubmitter.utils'
import CampaignNavigation from './components/CampaignNavigation/CampaignNavigation'
import { CampaignStatusProvider, normalizeCampaignStatus } from './hooks/useCampaignStatus'

const CampaignPage: FC = () => {
  const { id = 'new' } = useParams()
  const { data: campaign, isLoading: isCampaignLoading } = useCampaignQuery(id, {
    skip: id === 'new',
  })
  const { data: user, isLoading: isUserLoading } = useUserQuery()
  const { isLoading: isSocialAccountsLoading } = useSocialAccounts()
  const { isLoading: isSourceAccountsLoading } = useSourceAccounts()

  const defaultCampaign = useMemo(
    () => getDefaultCampaign({ id, user, campaign }),
    [id, user, campaign],
  )
  const defaultValues = useMemo(() => ({ campaigns: [defaultCampaign] }), [defaultCampaign])

  const isLoading =
    isCampaignLoading || isUserLoading || isSocialAccountsLoading || isSourceAccountsLoading

  return (
    <CampaignStatusProvider value={normalizeCampaignStatus(campaign?.status)}>
      <RootBox>
        <CampaignNavigation campaignName={campaign?.name} />
        {isLoading || !defaultCampaign ? (
          <PostsFormSkeleton />
        ) : (
          <CampaignsFormSubmitter
            defaultValues={defaultValues}
            renderPreview={(values) => (
              <CampaignPostPreviewContainer
                sourceAccountId={values.sourceAccountId}
                socialType={values.socialName}
                socialAccountId={values.socialAccountId}
                postType={values.postType}
                mediaRule={values.mediaRule}
                captionType={values.captionType}
                description={values.postTemplates?.[0] ?? ''}
                collectionIds={values.collectionIds ?? []}
              />
            )}
          />
        )}
      </RootBox>
    </CampaignStatusProvider>
  )
}

export default CampaignPage
