import { ProductAutocomplete } from 'COMPONENTS/forms'
import { type FC } from 'react'
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form'

interface ProductAutocompleteFieldProps<T extends FieldValues> {
  control: Control<T>
  sourceAccountId: string
  error?: boolean
  name: Path<T>
}

export const ProductAutocompleteField = <T extends FieldValues>({
  control,
  sourceAccountId,
  error,
  name,
}: ProductAutocompleteFieldProps<T>): ReturnType<FC> => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ProductAutocomplete
          color={error ? 'danger' : 'neutral'}
          sourceAccountId={sourceAccountId}
          options={[]}
          sx={{ flex: 2 }}
          placeholder="Choose product"
          value={field.value}
          onChange={(_, newValue) => {
            field.onChange(newValue)
          }}
          error={error}
        />
      )}
    />
  )
}
