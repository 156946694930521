import Alert, { type AlertProps } from '@mui/joy/Alert'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import type { FC } from 'react'
import { TbFreeRights } from 'react-icons/tb'

import { HELP_PORTAL_PRICING_INFO } from '../../../../../utils/constants'

const NoChargeAlert: FC<AlertProps> = (props) => {
  return (
    <Alert variant="soft" color="success" startDecorator={<TbFreeRights />} {...props}>
      <Stack width="100%">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontWeight="bold">No charge yet!</Typography>
        </Stack>
        <Typography>Enjoy using Amplorium for free while it&#39;s still in beta!</Typography>
      </Stack>
      <Link href={HELP_PORTAL_PRICING_INFO} target="_blank" sx={{ fontSize: 'inherit' }}>
        Why is Amplorium free?
      </Link>
    </Alert>
  )
}

export default NoChargeAlert
