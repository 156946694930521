/* eslint-disable @typescript-eslint/no-unused-vars */
import './chatwoot-customization.css'

import { type ReactNode, useEffect } from 'react'
import { useUserQuery } from 'STORE/endpoints/root'
import { CHATWOOT_WEBSITE_TOKEN } from 'UTILS/constants'

declare global {
  interface Window {
    chatwootSDK?: {
      run: (config: { websiteToken: string; baseUrl: string }) => void
    }
    $chatwoot?: {
      setUser: (
        id: string | undefined,
        user: { email: string | undefined; name: string | undefined },
      ) => void
    }
  }
}

const BASE_URL = 'https://app.chatwoot.com'
const SCRIPT_URL = `${BASE_URL}/packs/js/sdk.js`

const ChatwootLoader = (): ReactNode => {
  const { data: user, isSuccess } = useUserQuery()

  useEffect(() => {
    const loadChatwoot = (): void => {
      const script = document.createElement('script')
      script.src = SCRIPT_URL
      script.defer = true
      script.async = true

      script.onload = () => {
        if (window.chatwootSDK) {
          window.chatwootSDK.run({
            websiteToken: CHATWOOT_WEBSITE_TOKEN,
            baseUrl: BASE_URL,
          })
        }

        if (isSuccess && user) {
          window.$chatwoot?.setUser(user.id, {
            email: user.email,
            name: user.name,
          })
        }
      }

      document.body.appendChild(script)
    }

    loadChatwoot()

    // Clean up script when the component unmounts
    return () => {
      const existingScript = document.querySelector(`script[src="${SCRIPT_URL}"]`)
      if (existingScript) {
        document.body.removeChild(existingScript)
      }
    }
  }, [user, isSuccess])

  return null
}

export default ChatwootLoader
