import IconButton from '@mui/joy/IconButton'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'
import { TbTrash } from 'react-icons/tb'

import ShopifyIcon from '../../../../assets/img/shopify.svg?react'
import HorizontalCardSkeleton from '../../../../components/skeletons/HorizontalCardSkeleton/HorizontalCardSkeleton'
import useConfirm from '../../../../hooks/useConfirm/useConfirm'
import useSourceAccounts, { type NotEmptySourceAccount } from '../../../../hooks/useSourceAccounts'
import HorizontalCardStack from '../../../../ui/HorizontalCardStack/HorizontalCardStack'

const SourceAccountsContainer: FC = () => {
  const { sourceAccounts = [], isLoading: isSourceAccountsLoading } = useSourceAccounts()

  const confirm = useConfirm()

  const createDeleteClickHandler = (sourceAccount: NotEmptySourceAccount) => async () => {
    const isConfirmed = await confirm({
      title: 'Disconnect page',
      question: `Do you really want to delete ${sourceAccount.name ?? 'page'}?`,
      confirm: 'Disconnect',
    })

    console.log('delete sourceAccount', sourceAccount, isConfirmed)
  }

  if (isSourceAccountsLoading) {
    return <HorizontalCardSkeleton />
  }

  return sourceAccounts.length === 0 ? (
    <Typography>Has no accounts yet</Typography>
  ) : (
    <List sx={{ gap: 4 }}>
      {sourceAccounts.map((sourceAccount) => (
        <ListItem key={sourceAccount.id}>
          <HorizontalCardStack
            sx={{ width: '100%' }}
            icon={<ShopifyIcon width={36} height={36} />}
            content={
              <>
                <Typography fontWeight="md">{sourceAccount.name}</Typography>
                <Typography textColor="text.tertiary">{sourceAccount.email}</Typography>
              </>
            }
            action={
              sourceAccounts.length > 1 ? (
                <IconButton variant="outlined" onClick={createDeleteClickHandler(sourceAccount)}>
                  <TbTrash />
                </IconButton>
              ) : null
            }
          />
        </ListItem>
      ))}
    </List>
  )
}

export default SourceAccountsContainer
