import { type NotEmptySocialAccount } from 'HOOKS/useSocialAccounts'

import { type MediaValue, type PostType, type SocialType } from '@/types'

export interface PostFormValues {
  socialName: Exclude<SocialType, 'PINTEREST' | 'TIKTOK'>
  socialAccountId: string

  postType: PostType
  media: MediaValue[]

  postId?: string
  platform?: 'SHOPIFY'
  sourceAccountId?: string
  productId?: string
  caption?: string
}

export interface PostFormProps {
  socialAccounts: NotEmptySocialAccount[]
}

export enum PostFormField {
  Caption = 'caption',
  SourceAccountId = 'sourceAccountId',
  ProductId = 'productId',
  Media = 'media',
  PostType = 'postType',
  SocialName = 'socialName',
  SocialAccountId = 'socialAccountId',
}
