import Box from '@mui/joy/Box'
import type { ReactElement } from 'react'

import Spinner from '../Spinner/Spinner'
import { AnimationBox } from './AnimationBox'

export const MediaLoading = (): ReactElement => {
  return (
    <AnimationBox>
      <Box
        display="flex"
        sx={{
          border: '1px solid rgba(162, 159, 182, 0.30)',
          width: 68,
          height: 68,
          borderRadius: 'sm',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="md" />
      </Box>
    </AnimationBox>
  )
}
