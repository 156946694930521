import { type FC, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useCampaignsQuery, useRemoveCampaignsMutation } from 'STORE/endpoints/campaign'
import { pushErrorNotification, pushSuccessNotification } from 'STORE/notifications/actions'

import { useAppDispatch } from '@/store'

import BaseContextBar from '../../components/bars/BaseContextBar/BaseContextBar'
import useConfirm from '../../hooks/useConfirm/useConfirm'
import Pagination from '../../ui/Pagination/Pagination'
import TablePage from '../../ui/TablePage/TablePage'
import {
  getCampaignStatus,
  getDeleteConfirmationOptions,
  getNormalizedParams,
  getPage,
} from './CampaignPage.utils'
import CampaignsBar from './components/CampaignsBar/CampaignsBar'
import CampaignsStatusesContainer from './components/CampaignsStatusesContainer/CampaignsStatusesContainer'
import { useCampaignTable } from './hooks/useCampaignTable'

const CampaignsPage: FC = () => {
  const [params, setParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const confirm = useConfirm()

  const search = params.get('search')
  const socialAccountId = params.get('social')

  const { data: campaignPage, isLoading: isCampaignsLoading } = useCampaignsQuery(
    getNormalizedParams(params).toString(),
  )

  const [removeCampaigns, { isLoading: isRemovingCampaigns }] = useRemoveCampaignsMutation()

  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const status = getCampaignStatus(params)
  const page = getPage(params)

  const Table = useCampaignTable(status)

  useEffect(() => {
    setSelectedIds([])
  }, [status])

  const handlePageChange = useCallback(
    (newPage: number): void => {
      setParams((prevSearchParams) => {
        newPage === 1
          ? prevSearchParams.delete('page')
          : prevSearchParams.set('page', newPage.toString())

        return prevSearchParams
      })
    },
    [setParams],
  )

  const handleRemoveClick = useCallback(async (): Promise<void> => {
    const isConfirmed = campaignPage
      ? await confirm(getDeleteConfirmationOptions(campaignPage, selectedIds))
      : false

    if (!isConfirmed) return

    try {
      await removeCampaigns(selectedIds)
      dispatch(
        pushSuccessNotification({
          message: `${
            selectedIds.length > 1 ? 'Campaigns are' : 'The campaign is'
          } successfully deleted`,
        }),
      )
      setSelectedIds([])
    } catch (e) {
      console.error(e)
      dispatch(pushErrorNotification({ message: 'Something went wrong...' }))
    }
  }, [removeCampaigns, selectedIds, dispatch, setSelectedIds])

  const handleSocialAccountChange = useCallback(
    (value: string | null): void => {
      setParams((prevSearchParams) => {
        value === null ? prevSearchParams.delete('social') : prevSearchParams.set('social', value)
        return prevSearchParams
      })
    },
    [setParams],
  )

  const handleSearchChange = useCallback(
    (value: string | null): void => {
      setParams((prevSearchParams) => {
        prevSearchParams.delete('page')
        value === null ? prevSearchParams.delete('search') : prevSearchParams.set('search', value)
        return prevSearchParams
      })
    },
    [setParams],
  )

  return (
    <TablePage
      navigation={<CampaignsStatusesContainer activeStatusId={status} />}
      bar={
        selectedIds.length ? (
          <BaseContextBar
            label={`${selectedIds.length > 1 ? 'Campaigns' : 'Campaign'} selected`}
            selectedAmount={selectedIds.length}
            isRemoving={isRemovingCampaigns}
            onRemoveClick={handleRemoveClick}
          />
        ) : (
          <CampaignsBar
            onSearchChange={handleSearchChange}
            search={search}
            socialAccountId={socialAccountId}
            onSocialAccountChange={handleSocialAccountChange}
          />
        )
      }
    >
      <Table
        campaigns={campaignPage?.content}
        isLoading={isCampaignsLoading}
        selected={selectedIds}
        onSelectedChange={setSelectedIds}
      />

      {campaignPage?.totalPages !== undefined && campaignPage?.totalPages > 1 && (
        <Pagination page={page} onPageChange={handlePageChange} count={campaignPage?.totalPages} />
      )}
    </TablePage>
  )
}

export default CampaignsPage
