import { extendTheme } from '@mui/joy/styles'

import { Spinner } from '../ui'

declare module '@mui/joy/IconButton' {
  interface IconButtonPropsSizeOverrides {
    xs: true
  }
}

declare module '@mui/joy/MenuButton' {
  interface MenuButtonPropsSizeOverrides {
    xs: true
  }
}

declare module '@mui/joy/Card' {
  interface CardPropsVariantOverrides {
    ['outlined-blur']: true
  }
}

const commonControl = (): { minHeight: number } => ({
  minHeight: 40,
})

const lightTheme = extendTheme({
  fontFamily: {
    display: 'Inter, var(--joy-fontFamily-fallback)',
    body: 'Inter, var(--joy-fontFamily-fallback)',
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          solidBg: '#4564EB',
          solidHoverBg: '#3851C1',
          outlinedColor: '#32383E',
          plainColor: '#32383E',
          outlinedBorder: '#CDD7E1',
        },
      },
    },
  },
  components: {
    JoyInput: {
      styleOverrides: {
        root: commonControl,
      },
    },
    JoySelect: {
      styleOverrides: {
        root: commonControl,
      },
    },
    JoySnackbar: {
      styleOverrides: {
        root: {
          minWidth: '648px',
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'md' && { '--Button-minHeight': '2.5rem' }),
        }),
      },
      defaultProps: {
        loadingIndicator: <Spinner size="md" opacity={0.7} role="progressbar" />,
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'xs' && { height: '2rem', width: '2rem' }),
        }),
      },
    },
    JoyModal: {
      styleOverrides: {
        root: () => ({
          borderWidth: 0,
        }),
      },
    },
    JoyCard: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined-blur' && {
            background: 'rgba(221, 231, 238, 0.30)',
            backdropFilter: 'blur(5px)',
            border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
          }),
        }),
      },
    },
    JoyRadioGroup: {
      defaultProps: {
        sx: {
          width: 'fit-content',
        },
      },
    },
  },
})

export default lightTheme
