import Button from '@mui/joy/Button'
import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Bar } from '../../../../components/views'
import useCampaignStatus, { CampaignStatus } from '../../hooks/useCampaignStatus'
import useIsNewCampaignFlag from '../../hooks/useIsNewCampaignFlag'
import { CampaignsSubmitType } from '../CampaignFormSubmitter/CampaignsFormSubmitter.type'
import { type CampaignFormBarProps } from './CampaignFormBar.types'

const CampaignFormBar: FC<CampaignFormBarProps> = ({ isSubmitting }) => {
  const navigate = useNavigate()

  const isNewCampaign = useIsNewCampaignFlag()

  const handleCancel = (): void => {
    navigate(-1)
  }

  const status = useCampaignStatus()

  return (
    <Bar key="campaign-form-bar">
      <Button variant="outlined" onClick={handleCancel}>
        Cancel
      </Button>
      {isNewCampaign && (
        <Button
          sx={{ ml: 'auto' }}
          type="submit"
          name={CampaignsSubmitType.Instant}
          loading={isSubmitting}
        >
          Start campaign
        </Button>
      )}
      {!isNewCampaign && status === CampaignStatus.Paused && (
        <Button
          variant="outlined"
          sx={{ ml: 'auto' }}
          type="submit"
          name={CampaignsSubmitType.Renew}
          loading={isSubmitting}
        >
          Renew
        </Button>
      )}
      {!isNewCampaign && status === CampaignStatus.Active && (
        <Button
          variant="outlined"
          sx={{ ml: 'auto' }}
          type="submit"
          name={CampaignsSubmitType.Pause}
          loading={isSubmitting}
        >
          Pause campaign
        </Button>
      )}

      {!isNewCampaign && (
        <Button
          variant="solid"
          type="submit"
          name={CampaignsSubmitType.Update}
          loading={isSubmitting}
        >
          Update
        </Button>
      )}

      {!isNewCampaign && (status === CampaignStatus.Active || status === CampaignStatus.Paused) && (
        <Button
          color="danger"
          variant="solid"
          type="submit"
          name={CampaignsSubmitType.Remove}
          loading={isSubmitting}
        >
          Remove
        </Button>
      )}
    </Bar>
  )
}

export default CampaignFormBar
