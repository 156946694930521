import { FormControl, Input, Typography } from '@mui/joy'
import ErrorMessage from 'COMPONENTS/forms/ErrorMessage/ErrorMessage'
import { type FC } from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { LABEL_SX } from '../constants'
import { type ControlProps } from '../types'

export const NameControl: FC<ControlProps> = ({ name }) => {
  const { register, control } = useFormContext()

  const {
    fieldState: { error: nameError },
  } = useController({ control, name })

  return (
    <FormControl>
      <Typography sx={LABEL_SX}>Campaign name</Typography>

      <Input
        placeholder="Campaign name"
        error={nameError != null}
        {...register(name, { required: true })}
      />

      <ErrorMessage error={nameError} />
    </FormControl>
  )
}
