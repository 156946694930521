/* eslint-disable @typescript-eslint/no-invalid-void-type */
import type { EmailNotificationUpdateRequest, UserResponse } from '../../types'
import api from '../api'

const rootApi = api.injectEndpoints({
  endpoints: (builder) => ({
    user: builder.query<UserResponse, void>({
      query: () => 'api/user',
      providesTags: ['User'],
    }),
    getNotifications: builder.query<EmailNotificationUpdateRequest[], void>({
      query: () => ({
        url: '/api/user/settings/notifications',
        method: 'GET',
      }),
      providesTags: ['UserNotificationsSettings'],
    }),
    editNotifications: builder.mutation<void, EmailNotificationUpdateRequest[]>({
      query: (body: EmailNotificationUpdateRequest[]) => ({
        url: '/api/user/settings/notifications',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['UserNotificationsSettings'],
    }),
  }),
})

export const {
  useUserQuery,
  useLazyUserQuery,
  useGetNotificationsQuery,
  useEditNotificationsMutation,
} = rootApi

export default rootApi
