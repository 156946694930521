import FormControl from '@mui/joy/FormControl'
import Typography from '@mui/joy/Typography'
import ErrorMessage from 'COMPONENTS/forms/ErrorMessage/ErrorMessage'
import type { FC } from 'react'
import { useController, useFormContext, useFormState } from 'react-hook-form'
import { MediaWithProduct } from 'UI/Media/MediaWithProduct'

import { PostFormField } from '../../PostForm.types'
import { labelsSx } from '../../styles'

export const MediaControl: FC = () => {
  const { control } = useFormContext()

  const {
    fieldState: { error: mediaError },
  } = useController({ control, name: PostFormField.Media })

  const {
    field: { value: sourceAccountId },
  } = useController({ control, name: PostFormField.SourceAccountId })

  const {
    field: { value: productId },
  } = useController({ control, name: PostFormField.ProductId })

  const {
    field: { value: postType },
  } = useController({ control, name: PostFormField.PostType })

  const {
    field: { value: socialName },
  } = useController({ control, name: PostFormField.SocialName })

  const { errors } = useFormState({ control })
  /* const { // TODO: check
    fieldState: { error: formError },
  } = useFormState({ control }) */

  const videoEditorDisabled =
    (postType === 'POST' && socialName === 'INSTAGRAM') ||
    (postType === 'POST' && socialName === 'FACEBOOK')

  return (
    <FormControl>
      <Typography id="post-media-label" sx={labelsSx} component="label">
        Include media
      </Typography>

      <MediaWithProduct
        videoEditorDisabled={videoEditorDisabled}
        control={control}
        productId={productId}
        sourceAccountId={sourceAccountId}
        name={PostFormField.Media}
        error={mediaError !== undefined}
      />

      <ErrorMessage error={mediaError} />
      {
        // TODO: check
      }
      {errors.media?.type === 'check-video' && <ErrorMessage error={errors.media} />}
    </FormControl>
  )
}
