import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { FormGroup } from '@mui/material'
import ErrorMessage from 'COMPONENTS/forms/ErrorMessage/ErrorMessage'
import type { FC } from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { PostFormField } from '../../PostForm.types'
import { labelsSx } from '../../styles'
import { ProductAutocompleteField } from './components/ProductAutocompleteField/ProductAutocompleteField'
import { SourceAccountSelect } from './components/SourceAccountSelect/SourceAccountSelect'

export const ProductControl: FC = () => {
  const { setValue, control } = useFormContext()
  const {
    fieldState: { error: productError },
  } = useController({ control, name: PostFormField.ProductId })

  const {
    field: { value: sourceAccountId },
  } = useController({ control, name: PostFormField.SourceAccountId })

  const handleAccountChange = (): void => {
    setValue(PostFormField.ProductId, null)
  }

  return (
    <FormGroup>
      <Typography sx={labelsSx} id="chose-source-account-select-label" component="label">
        Product
      </Typography>

      <Stack direction="row" gap={2} flex={1}>
        <SourceAccountSelect
          control={control}
          onAccountChange={handleAccountChange}
          name={PostFormField.SourceAccountId}
        />

        {sourceAccountId && (
          <ProductAutocompleteField
            control={control}
            sourceAccountId={sourceAccountId}
            error={!!productError}
            name={PostFormField.ProductId}
          />
        )}
      </Stack>
      <ErrorMessage error={productError} />
    </FormGroup>
  )
}
