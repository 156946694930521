import Stack from '@mui/joy/Stack'
import { type FC } from 'react'
import { useController, useFormContext } from 'react-hook-form'

import { CaptionControl } from './components/CaptionControl/CaptionControl'
import { MediaControl } from './components/MediaControl/MediaControl'
import { PageControl } from './components/PageControl/PageControl'
import { PostTypeControl } from './components/PostTypeControl/PostTypeControl'
import { ProductControl } from './components/ProductControl/ProductControl'
import { PostFormField, type PostFormProps } from './PostForm.types'

const PostForm: FC<PostFormProps> = ({ socialAccounts }) => {
  const { control } = useFormContext()

  const {
    field: { value: postType },
  } = useController({ control, name: PostFormField.PostType })

  const showCaption = postType === 'POST' || postType === 'REEL'

  return (
    <Stack gap={4}>
      <PageControl socialAccounts={socialAccounts} />
      <PostTypeControl />
      <ProductControl />
      <MediaControl />

      {showCaption && <CaptionControl />}
    </Stack>
  )
}

export default PostForm
