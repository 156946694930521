import useConfirm from 'HOOKS/useConfirm/useConfirm'
import { type BaseSyntheticEvent, type FC } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import api from 'STORE/api'
import {
  useCreateCampaignMutation,
  useEditCampaignMutation,
  useEditCampaignStatusMutation,
} from 'STORE/endpoints/campaign'
import { type AppDispatch } from 'STORE/store'

import CampaignsForm from '../CampaignsForm/CampaignsForm'
import { type CampaignsFormValues } from '../CampaignsForm/CampaignsForm.types'
import {
  type CampaignsFormSubmitterProps,
  CampaignsSubmitType,
} from './CampaignsFormSubmitter.type'
import { getSubmitType, toCampaign } from './CampaignsFormSubmitter.utils'

const CampaignsFormSubmitter: FC<CampaignsFormSubmitterProps> = (props) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [createCampaign] = useCreateCampaignMutation()
  const [editCampaign] = useEditCampaignMutation()
  const [editCampaignStatus] = useEditCampaignStatusMutation()
  const confirm = useConfirm()

  // todo: refactor this function, bring into relation PostFormContainer
  const handleSubmit = async (
    { campaigns }: CampaignsFormValues,
    event?: BaseSyntheticEvent,
  ): Promise<void> => {
    event?.stopPropagation()
    const submitType = getSubmitType(event)

    let results

    switch (submitType) {
      case CampaignsSubmitType.Remove:
        {
          if (id === undefined) {
            return
          }

          const confirmed = await confirm({
            question: 'Campaign will be removed',
            confirm: 'Remove',
          })

          if (!confirmed) {
            return
          }

          results = [await editCampaignStatus({ campaignId: id, status: 'DELETED' })]
        }
        break
      case CampaignsSubmitType.Renew:
      case CampaignsSubmitType.Pause:
        if (id === undefined) {
          return
        }

        results = [await editCampaignStatus({ campaignId: id, status })]
        break
      case CampaignsSubmitType.Update:
        if (id === undefined) {
          return
        }

        results = await Promise.allSettled(
          campaigns.map(async (campaignFormValue): Promise<unknown> => {
            return await editCampaign({ id, ...toCampaign(campaignFormValue) })
          }),
        )
        break
      default:
        results = await Promise.allSettled(
          campaigns.map(async (campaignFormValue): Promise<unknown> => {
            return await createCampaign(toCampaign(campaignFormValue))
          }),
        )
    }

    const hasSubmitError = results.some((result) => 'error' in result)

    if (hasSubmitError) {
      console.error(results)

      return
    }

    navigate(`/campaigns?status=${status}`)
    // todo: fix by add cache invalidation for specigitfic tag
    dispatch(api.util.resetApiState())
  }

  return <CampaignsForm {...props} onSubmit={handleSubmit} />
}

export default CampaignsFormSubmitter
