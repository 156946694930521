import { SourceAccountSelectV2 } from 'COMPONENTS/forms'
import { type FC } from 'react'
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form'

interface SourceAccountSelectProps<T extends FieldValues> {
  control: Control<T>
  onAccountChange?: (accountId: string | null) => void
  name: Path<T>
}

export const SourceAccountSelect = <T extends FieldValues>({
  control,
  onAccountChange,
  name,
}: SourceAccountSelectProps<T>): ReturnType<FC> => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <SourceAccountSelectV2
          sx={{ flex: 1 }}
          placeholder="Choose shop"
          onChange={(_, newValue) => {
            field.onChange(newValue)
            onAccountChange?.(newValue)
          }}
          value={field.value}
        />
      )}
    />
  )
}
