import { yupResolver } from '@hookform/resolvers/yup'
import BoxWithBar from 'COMPONENTS/views/BoxWithBar/BoxWithBar'
import FormCard from 'COMPONENTS/views/FormCard/FormCard'
import PreviewBox from 'COMPONENTS/views/PreviewBox/PreviewBox'
import { TransparentForm } from 'COMPONENTS/views/TransparentForm/TransparentForm'
import { type FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import PostsFormBar from '../PostsFormBar/PostsFormBar'
import PostForm from './components/PostForm/PostForm'
import { type PostFormValues } from './components/PostForm/PostForm.types'
import { postFormSchema } from './PostsForm.schema'
import { type PostsFormProps } from './PostsForm.types'

const PostsForm: FC<PostsFormProps> = ({
  defaultValues,
  socialAccounts,
  renderPostFormPreview,
  onSubmit,
}) => {
  const form = useForm<PostFormValues>({
    defaultValues,
    resolver: yupResolver(postFormSchema),
  })

  return (
    <TransparentForm onSubmit={form.handleSubmit(onSubmit)}>
      <FormProvider {...form}>
        <BoxWithBar bar={<PostsFormBar isSubmitting={form.formState.isSubmitting} />}>
          {!form.formState.isLoading && (
            <PreviewBox
              preview={
                <>
                  {renderPostFormPreview !== undefined
                    ? renderPostFormPreview(form.getValues())
                    : 'Post preview unavailable'}
                </>
              }
            >
              <FormCard>
                <PostForm socialAccounts={socialAccounts} />
              </FormCard>
            </PreviewBox>
          )}
        </BoxWithBar>
      </FormProvider>
    </TransparentForm>
  )
}

export default PostsForm
