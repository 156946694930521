import type {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'

import { expireSession } from './auth/actions'
import { pushErrorNotification } from './notifications/actions'

const baseQuery = fetchBaseQuery({ baseUrl: '/' })

const mutex = new Mutex()

const DEFAULT_ERROR_MESSAGE = 'Something went wrong'
function intercept(api: BaseQueryApi, error: FetchBaseQueryError | undefined): void {
  if (typeof error === 'object' && error !== null && 'status' in error) {
    if (error.status === 400) {
      const message = (error.data as { message?: string })?.message ?? DEFAULT_ERROR_MESSAGE
      api.dispatch(
        pushErrorNotification({
          message,
        }),
      )
    } else {
      api.dispatch(
        pushErrorNotification({
          message: DEFAULT_ERROR_MESSAGE,
        }),
      )
    }
  }
}

const baseQueryWithAutoRefetch: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)

  if ('error' in result) {
    if (result.error?.status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()

        try {
          const refreshResult = await baseQuery(
            { url: 'auth/refresh', method: 'POST' },
            api,
            extraOptions,
          )

          if ('error' in refreshResult) {
            api.dispatch(expireSession())
          } else {
            // repeat request
            result = await baseQuery(args, api, extraOptions)
          }
        } finally {
          release()
        }
      } else {
        await mutex.waitForUnlock()
        result = await baseQuery(args, api, extraOptions)
      }
    } else {
      intercept(api, result.error)
    }
  }

  return result
}

const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithAutoRefetch,
  tagTypes: [
    'Post',
    'Post-item',
    'User',
    'Post-statistics',
    'Post-templates',
    'Campaign',
    'UserNotificationsSettings',
    'Campaign-statistics',
  ],
  endpoints: () => ({}),
})

export default api
