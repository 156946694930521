import { type FC } from 'react'
import { type IconBaseProps } from 'react-icons'
import { TbCalendarRepeat, TbCalendarTime, TbSquarePlus } from 'react-icons/tb'

interface PostIndicatorIconProps extends IconBaseProps {
  hasCampaign?: boolean
  hasScheduledTime?: boolean
}

const PostIndicatorIcon: FC<PostIndicatorIconProps> = ({
  hasCampaign,
  hasScheduledTime,
  ...props
}) => {
  if (hasCampaign) return <TbCalendarRepeat {...props} />
  if (hasScheduledTime) return <TbCalendarTime {...props} />
  return <TbSquarePlus {...props} />
}

export default PostIndicatorIcon
