import { FormControl, RadioGroup, Typography } from '@mui/joy'
import { type ChangeEvent, type FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TbDatabase, TbTag } from 'react-icons/tb'
import Radio from 'UI/Radio/Radio'

import { LABEL_SX } from '../constants'
import { type ControlProps } from '../types'

export const ProductRuleTypeControl: FC<ControlProps> = ({ name }) => {
  const { control } = useFormContext()

  return (
    <FormControl>
      <Typography sx={LABEL_SX}>Selection type</Typography>

      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup
            value={field.value ?? ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              field.onChange(event.target.value)
            }}
            variant="outlined"
            orientation="horizontal"
          >
            <Radio
              overlay
              disableIcon
              value="COLLECTIONS"
              label={
                <>
                  <TbDatabase />
                  <Typography>Collection</Typography>
                </>
              }
            />
            <Radio
              overlay
              value="TAGS"
              disableIcon
              label={
                <>
                  <TbTag />
                  <Typography>Tag</Typography>
                </>
              }
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}
