import { IconButton, type IconButtonProps } from '@mui/joy'
import { TbTrash } from 'react-icons/tb'

const RemoveIconButton: React.FC<IconButtonProps> = (props) => (
  <IconButton variant="outlined" {...props}>
    <TbTrash />
  </IconButton>
)

export default RemoveIconButton
