import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '@mui/joy/Button'
import ButtonGroup from '@mui/joy/ButtonGroup'
import IconButton from '@mui/joy/IconButton'
import Menu from '@mui/joy/Menu'
import MenuItem from '@mui/joy/MenuItem'
import * as React from 'react'

interface SplitButtonProps {
  options: string[]
  handleOptionClick: (option: string, optionIndex: number) => void
}

const SplitButton: React.FC<SplitButtonProps> = (props) => {
  const { options, handleOptionClick } = props
  const [open, setOpen] = React.useState(false)
  const actionRef = React.useRef<() => void>(null)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(1)
  const selectedOption = options[selectedIndex]

  const handleClick = (): void => {
    handleOptionClick(selectedOption, selectedIndex)
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    index: number,
  ): void => {
    setOpen(false)
    setSelectedIndex(index)
  }

  const handleDown = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    actionRef.current = () => {
      setOpen(!open)
    }
  }, [actionRef, setOpen])

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [actionRef, setOpen])

  const handleIconButtonClick = React.useCallback(() => {
    actionRef.current?.()
  }, [actionRef])

  return (
    <React.Fragment>
      <ButtonGroup ref={anchorRef} variant="outlined" color="primary" aria-label="split button">
        <Button onClick={handleClick}>{selectedOption}</Button>
        <IconButton
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onMouseDown={handleDown}
          onKeyDown={handleDown}
          onClick={handleIconButtonClick}
        >
          <ArrowDropDownIcon />
        </IconButton>
      </ButtonGroup>
      <Menu open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => {
              handleMenuItemClick(event, index)
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  )
}

export default SplitButton
