import FormControl from '@mui/joy/FormControl'
import Input from '@mui/joy/Input'
import Option from '@mui/joy/Option'
import Stack from '@mui/joy/Stack'
import { type FC, memo, type SyntheticEvent } from 'react'
import { IoSearchSharp } from 'react-icons/io5'

import SocialAccountSelectV2 from '../../../../components/forms/SocialAccountSelectV2/SocialAccountSelectV2'
import { type CampaignsFilterProps } from './types'

const CampaignsFilter: FC<CampaignsFilterProps> = ({
  children,
  socialAccountId,
  search,
  onSearchChange,
  onSocialAccountChange,
  ...props
}) => {
  const handleSearchChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    const newValue = (event.target as HTMLInputElement).value
    if (newValue.length >= 2) {
      onSearchChange(newValue)
    }
    if (newValue === '') {
      onSearchChange(null)
    }
  }

  const handleSocialAccountChange = (_: unknown, socialId: string | null): void => {
    onSocialAccountChange(socialId === 'ANY' ? null : socialId)
  }

  return (
    <Stack direction="row" justifyContent="space-between" {...props}>
      <FormControl size="md">
        <SocialAccountSelectV2
          sx={{ width: 195 }}
          size="md"
          placeholder="Page is Any"
          value={socialAccountId}
          onChange={handleSocialAccountChange}
          renderValue={(option) => <>Page is {option !== null ? option?.label : 'Any'}</>}
        >
          <Option value="ANY">Any</Option>
        </SocialAccountSelectV2>
      </FormControl>
      {children}
      <FormControl size="md" sx={{ ml: 'auto' }}>
        <Input
          defaultValue={search ?? ''}
          placeholder="Search"
          startDecorator={<IoSearchSharp size={18} />}
          onChange={handleSearchChange}
        />
      </FormControl>
    </Stack>
  )
}

export default memo(CampaignsFilter)
