import { Box, FormControl, IconButton, Stack, TabPanel, Textarea, Typography } from '@mui/joy'
import Tab from '@mui/joy/Tab'
import TabList from '@mui/joy/TabList'
import Tabs from '@mui/joy/Tabs'
import { AIDescriptionTools, TemplateTools } from 'COMPONENTS/ai'
import ErrorMessage from 'COMPONENTS/forms/ErrorMessage/ErrorMessage'
import { type FC, useState } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import { TbPlus, TbX } from 'react-icons/tb'

import { LABEL_SX } from '../constants'
import { type ControlProps } from '../types'

interface CaptionControlProps extends ControlProps {
  sourceAccountId: string
}

export const CaptionControl: FC<CaptionControlProps> = ({ name, sourceAccountId }) => {
  const { control, setValue } = useFormContext()

  const {
    field: { value },
    fieldState: { error: postTemplatesError },
  } = useController({ control, name })

  const [activeTab, setActiveTab] = useState(0)

  const tabs = value.map((_: string | undefined, index: number) => index)

  const createRemoveHandler = (tabForRemove: number) => (): void => {
    const newValue = value.filter((_: string | undefined, index: number) => index !== tabForRemove)

    setValue(name, newValue)
    // todo: get rid of this hack
    setTimeout(() => {
      setActiveTab(0)
    }, 0)
  }

  const handleAdd = (): void => {
    const newValue = [...value, '']

    setActiveTab(newValue.length - 1)
    setValue(name, newValue)
  }

  return (
    <FormControl>
      <Typography sx={LABEL_SX} id="post-title-label" component="label">
        Caption
      </Typography>
      <Box>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => {
            if (typeof newValue === 'number') {
              setActiveTab(newValue)
            } else {
              console.error('Unxpected tab value')
            }
          }}
        >
          <TabList
            disableUnderline
            sx={{
              height: 40,
              gap: 2,
              borderRadius: 'sm',
              mb: 2,
              overflow: 'auto',
              scrollSnapType: 'x mandatory',
              display: 'flex',
            }}
          >
            {tabs.map((tab: number) => (
              <Tab
                variant="outlined"
                key={tab}
                sx={{
                  width: 125,
                  borderRadius: 'sm',
                  textWrap: 'nowrap',
                  flex: 'none',
                  scrollSnapAlign: 'start',
                }}
                component="div"
                disableIndicator
                value={tab}
              >
                Variant {tab + 1}
                {tabs.length !== 1 && (
                  <IconButton
                    sx={{
                      '--variant-plainHoverBg': 'var(--joy-palette-primary-outlinedHoverBg)',
                    }}
                    size="xs"
                    onClick={createRemoveHandler(tab)}
                  >
                    <TbX />
                  </IconButton>
                )}
              </Tab>
            ))}

            <IconButton sx={{ width: 40 }} color="neutral" variant="outlined" onClick={handleAdd}>
              <TbPlus />
            </IconButton>
          </TabList>
          {tabs.map((tab: number) => (
            <TabPanel value={tab} sx={{ p: 0 }} key={tab}>
              <Stack gap={1}>
                <Controller
                  name={`${name}.${tab}`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <>
                        <TemplateTools
                          text={field.value ?? ''}
                          onTextChange={(newValue) => {
                            field.onChange(newValue)
                          }}
                        />
                        <Box sx={{ position: 'relative' }}>
                          <Box sx={{ position: 'absolute', zIndex: 1, p: '12px' }}>
                            <AIDescriptionTools
                              sourceAccountId={sourceAccountId}
                              accountPlatform="FACEBOOK"
                              description={field.value ?? ''}
                              onChange={(newDescription: string): void => {
                                field.onChange(newDescription)
                              }}
                            />
                          </Box>
                          <Textarea
                            sx={{ pt: 6 }}
                            minRows={3}
                            placeholder="Enter or generate caption..."
                            value={field.value ?? ''}
                            onChange={(newValue) => {
                              field.onChange(newValue)
                            }}
                          />
                        </Box>
                      </>
                    )
                  }}
                />
              </Stack>
            </TabPanel>
          ))}
        </Tabs>
      </Box>

      <ErrorMessage error={postTemplatesError} />
    </FormControl>
  )
}
