import { type LottieVideoOptions, VideoEditPopup } from '@amplorium/lottie-editor'
import Box from '@mui/joy/Box'
import Button, { type ButtonProps } from '@mui/joy/Button'
import Tooltip from '@mui/joy/Tooltip'
import Typography from '@mui/joy/Typography'
import { type FC, useState } from 'react'
import { TbPhotoVideo } from 'react-icons/tb'

import { AnimationBox } from './AnimationBox'
import { AUDIOS, TEMPLATES } from './constant'

export const VideoIconButton: FC<
  { onVideoUploaded: (options: LottieVideoOptions) => void; mediaUrls: string[] } & ButtonProps
> = ({ onVideoUploaded, mediaUrls, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  const createVideoButton = (
    <Button
      variant="outlined"
      component="label"
      sx={{
        height: 68,
        width: 68,
        opacity: props.disabled ? 0.4 : 1,
      }}
      onClick={() => {
        setIsOpen(true)
      }}
      {...props}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography sx={{ display: 'contents' }} color="primary">
          <TbPhotoVideo size={24}></TbPhotoVideo>
        </Typography>

        <Typography
          color="primary"
          component="span"
          sx={{
            fontSize: 9,
            lineHeight: '20px',
            letterSpacing: 0.585,
            fontWeight: 600,
          }}
        >
          CREATE
        </Typography>
      </Box>
    </Button>
  )
  return (
    <AnimationBox color="primary">
      {props.disabled ? (
        <Tooltip title="Video creation is unavailable for this type on Post" arrow>
          <div>{createVideoButton}</div>
        </Tooltip>
      ) : (
        createVideoButton
      )}
      <VideoEditPopup
        audioList={AUDIOS}
        mediaUrls={mediaUrls}
        templates={TEMPLATES}
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        onCreateVideo={onVideoUploaded}
      />
    </AnimationBox>
  )
}
