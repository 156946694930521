import { type CampaignsFormProps } from '../CampaignsForm/CampaignsForm.types'

export interface CampaignFormSubmitterProps {
  campaigns: any[]
}

export enum CampaignsSubmitType {
  // Draft = 'draft',
  Instant = 'instant',
  Pause = 'pause',
  Remove = 'remove',
  Renew = 'renew',
  Update = 'update',
}

export type CampaignsFormSubmitterProps = Omit<CampaignsFormProps, 'onSubmit'>
