export function mapStatistic<T extends { id: string }>(
  items: T[],
  statisticMap: Record<string, number | undefined>,
): T[] {
  return items.map((item: T): T => {
    const itemsCount = statisticMap[item.id]

    return Number.isFinite(itemsCount) ? { ...item, itemsCount } : item
  })
}
