import type { FC, ReactElement, SVGProps } from 'react'

import SpinnerSvg from '../../assets/img/spinner.svg?react'

export interface SpinnerProps extends SVGProps<SVGSVGElement> {
  size?: 'md' | 'lg' | 'xs' | 'sm'
}

const Spinner: FC<SpinnerProps> = ({ size, style, ...props }): ReactElement => {
  let finalSize

  switch (size) {
    case 'xs':
      finalSize = '18px'
      break
    case 'sm':
      finalSize = '28px'
      break
    case 'md':
      finalSize = '36px'
      break
    case 'lg':
    default:
      finalSize = '42px'
      break
  }

  return <SpinnerSvg width={finalSize} height={finalSize} {...props} />
}

export default Spinner
