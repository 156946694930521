/* eslint-disable no-extra-boolean-cast */
import IconButton from '@mui/joy/IconButton'
import Link from '@mui/joy/Link'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import Stack from '@mui/joy/Stack'
import Tooltip from '@mui/joy/Tooltip'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'
import { TbAlertCircle, TbCircleCheck, TbExternalLink, TbTrash } from 'react-icons/tb'

import SocialAccountAvatar from '../../../../../components/views/SocialAccountAvatar/SocialAccountAvatar'
import { type NotEmptySocialAccount } from '../../../../../hooks/useSocialAccounts'
import HorizontalCardStack from '../../../../../ui/HorizontalCardStack/HorizontalCardStack'

const SocialAccounts: FC<{
  socialAccounts: NotEmptySocialAccount[]
  onDeleteClick?: (socialAccount: NotEmptySocialAccount) => void
}> = ({ socialAccounts, onDeleteClick }) => {
  const createDeleteClickHandler = (socialAccount: NotEmptySocialAccount) => () => {
    onDeleteClick?.(socialAccount)
  }

  return (
    <List sx={{ gap: 4 }}>
      {socialAccounts.map((socialAccount) => (
        <ListItem key={socialAccount.id}>
          <HorizontalCardStack
            sx={{ width: '100%' }}
            icon={<SocialAccountAvatar socialAccount={socialAccount} />}
            content={
              <Stack direction="row" alignItems="center" gap={2}>
                <Tooltip title={socialAccount.username} variant="plain">
                  <Typography
                    fontWeight="md"
                    noWrap
                    sx={{ maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {socialAccount.name}
                  </Typography>
                </Tooltip>
                <Tooltip
                  title={
                    socialAccount.expired === true
                      ? 'Your social account has been disconnected. Please reconnect.'
                      : 'Your social account has been connected successfully.'
                  }
                  variant="plain"
                  placement="right"
                >
                  <Typography
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                    color={socialAccount.expired === true ? 'danger' : 'success'}
                  >
                    {socialAccount.expired === true ? (
                      <TbAlertCircle size={24} />
                    ) : (
                      <TbCircleCheck size={24} />
                    )}
                  </Typography>
                </Tooltip>
              </Stack>
            }
            action={
              <Stack gap={2} direction="row">
                <Link href={socialAccount.link} target="_blank" gap={1}>
                  <Typography>View page</Typography>
                  <TbExternalLink />
                </Link>
                <IconButton variant="outlined" onClick={createDeleteClickHandler(socialAccount)}>
                  <TbTrash />
                </IconButton>
              </Stack>
            }
          />
        </ListItem>
      ))}
    </List>
  )
}

export default SocialAccounts
