interface GetTooltipTitleProps {
  hasCampaign?: boolean
  hasScheduledTime?: boolean
  campaignName?: string
}
export const getTooltipTitle = ({
  campaignName,
  hasCampaign,
  hasScheduledTime,
}: GetTooltipTitleProps): string => {
  if (hasCampaign)
    return campaignName ? `Created from '${campaignName}' campaign` : 'Created from campaign'
  if (hasScheduledTime) return 'Scheduled post'

  return 'Manual post'
}
