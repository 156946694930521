import GlobalStyles from '@mui/joy/GlobalStyles'
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles'
import CssBaseline from '@mui/material/CssBaseline'
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ChatwootLoader from 'COMPONENTS/chatwoot/Chatwoot'
import type { PropsWithChildren, ReactElement } from 'react'
import { IconContext } from 'react-icons'
import { Provider } from 'react-redux'

import ConfirmContextProvider from '../hooks/useConfirm/components/ConfirmContextProvider/ConfirmContextProvider'
import TimeSchedulerProvider from '../hooks/useTimeScheduler/components/TimeSchedulerProvider/TimeSchedulerProvider'
import { TimezoneProvider } from '../hooks/useTimezone/components/TimezoneProvider/TimezoneProvider'
import { store } from '../store'
import lightTheme from '../themes/light'
import NotificationsContainer from './notifications/NotificationsContainer'

const materialTheme = materialExtendTheme()

const Layout = ({ children }: PropsWithChildren): ReactElement => {
  return (
    <JoyCssVarsProvider theme={lightTheme}>
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <CssBaseline enableColorScheme />
        <GlobalStyles
          styles={{
            html: {
              height: '100%',
              width: '100%',
            },
            body: {
              height: '100%',
              width: '100%',
              background: 'var(--joy-palette-background-body)',
              '#app': {
                height: '100%',
                display: 'flex',
                overflow: 'auto',
                background: 'var(--joy-palette-background-level1)',
                '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                '--Cover-width': '50vw', // must be `vw` only
                '--Form-maxWidth': '800px',
                '--Transition-duration': '0.4s', // set to `none` to disable transition
              },
            },
          }}
        />
        <IconContext.Provider value={{ color: 'text.icon', size: '1.25rem' }}>
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <NotificationsContainer />
              <TimezoneProvider>
                <TimeSchedulerProvider>
                  <ConfirmContextProvider>{children}</ConfirmContextProvider>
                </TimeSchedulerProvider>
              </TimezoneProvider>
              <ChatwootLoader />
            </LocalizationProvider>
          </Provider>
        </IconContext.Provider>
      </MaterialCssVarsProvider>
    </JoyCssVarsProvider>
  )
}

export default Layout
