import { Alert, FormControl, Input } from '@mui/joy'
import ErrorMessage from 'COMPONENTS/forms/ErrorMessage/ErrorMessage'
import { type FC } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { TbInfoOctagon } from 'react-icons/tb'

import { type ControlProps } from '../types'

export const SpecialInstructionControl: FC<ControlProps> = ({ name }) => {
  const { register, control } = useFormContext()

  const {
    fieldState: { error },
  } = useController({ control, name })

  return (
    <FormControl>
      <Alert sx={{ mb: 2 }} startDecorator={<TbInfoOctagon></TbInfoOctagon>}>
        We’ll generate a unique caption for every post based on the product details.
      </Alert>

      <Input
        placeholder="Additional instructions (optional)"
        error={error != null}
        {...register(name, { required: true })}
      />
      <ErrorMessage error={error} />
    </FormControl>
  )
}
