import Button from '@mui/joy/Button'
import { type FC, memo, type PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

import Bar from '../../../../ui/Bar/Bar'
import CampaignsFilter from '../CampaignsFilter/CampaignsFilter'

interface CampaignsBarProps extends PropsWithChildren {
  socialAccountId: string | null
  search: string | null
  onSocialAccountChange: (newValue: string | null) => void
  onSearchChange: (newValue: string | null) => void
}

const CampaignsBar: FC<CampaignsBarProps> = ({
  socialAccountId,
  onSocialAccountChange,
  onSearchChange,
  search,
}) => {
  const navigate = useNavigate()

  const handleCreateCampaign = (): void => {
    navigate('new')
  }

  return (
    <Bar>
      <CampaignsFilter
        flex={1}
        socialAccountId={socialAccountId}
        search={search}
        onSocialAccountChange={onSocialAccountChange}
        onSearchChange={onSearchChange}
      />

      <Button size="md" onClick={handleCreateCampaign}>
        New campaign
      </Button>
    </Bar>
  )
}

export default memo(CampaignsBar)
